import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { linkedin, google } from 'assets/icons';
// import { useNavigate } from 'react-router-dom';
import { success } from 'assets/images';
import Swal from 'sweetalert2';

function SignInForm() {
	const strongPasswordRegex =
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
	// const navigate = useNavigate();
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
			password: Yup.string()
				.min(8)
				.required('Required')
				.matches(strongPasswordRegex),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/api/auth/signin',
				data: formik.values,
				headers: { 'Content-Type': 'application/json' },
			})
				.then(function (response) {
					console.log(response);
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submitted Successfully',
						text: 'You have successfully Signed In.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
					// navigate('/');
				})
				.catch(function () {
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submission was Unsuccessful',
						text: 'User with Email Not Found. Kindly try again.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
				});
		},
	});
	return (
		<form onSubmit={formik.handleSubmit} className='lg:p-10 xl:p-10 2xl:p-10'>
			<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-80 py-8 text-2xl'>
				Log In to your Account
			</h1>
			<div className='flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-between gap-x-4 gap-y-4'>
				<div className='border p-2 rounded md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-white flex justify-center items-center'>
					<a
						href='#'
						rel='noreferrer'
						target='_blank'
						className='text-white flex gap-x-2'
					>
						<img src={google} width={20} />{' '}
						<p className='text-sm'>Continue with Google</p>
					</a>
				</div>
				<div className='border p-2 rounded md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-white flex justify-center items-center'>
					<a
						href='#'
						rel='noreferrer'
						target='_blank'
						className='text-white flex gap-x-2'
					>
						<img src={linkedin} width={20} />{' '}
						<p className='text-sm'>Continue with LinkedIn</p>
					</a>
				</div>
			</div>
			<div className='text-center flex pt-10 justify-between items-center pb-4'>
				<div className='border-b border-lilac w-80 text-lilac'></div>{' '}
				<Link to='#' className='px-2 text-center text-white text-sm'>
					Or continue with
				</Link>
				<div className='border-b border-lilac w-80 text-lilac'></div>
			</div>

			<div className='grid grid-cols-1 pt-4'>
				<label htmlFor='email' className='text-white text-base'>
					Email Address <b className='text-red text-base'>*</b>
				</label>
				<input
					name='email'
					type='email'
					className='p-1 rounded text-sm'
					placeholder='Enter your Email Address'
					onChange={formik.handleChange}
				/>
				{formik.touched.email && formik.errors.email ? (
					<div className='text-red text-base'>{formik.errors.email}</div>
				) : null}
			</div>

			<div className='grid grid-cols-1 pt-4'>
				<label htmlFor='password' className='text-white text-base'>
					Password <b className='text-red text-base'>*</b>
				</label>
				<input
					name='password'
					type='password'
					className='w-full p-1 rounded text-sm'
					placeholder='********'
					onChange={formik.handleChange}
				/>
				{formik.touched.email && formik.errors.email ? (
					<div className='text-red text-base'>{formik.errors.email}</div>
				) : null}
			</div>

			<div className='grid grid-cols-1 pt-10 mb-8'>
				<label htmlFor='check' className='text-white text-base'>
					<input
						type='checkbox'
						name='check'
						value='check'
						className='bg-white rounded mr-2 text-base'
					/>
					Remember me
				</label>
			</div>

			<div className='grid grid-cols-3'>
				<div></div>
				<div className='flex justify-center'>
					<button
						type='submit'
						className='w-32 h-12 text-base bg-purple text-white rounded'
					>
						Sign In
					</button>
				</div>
				<div></div>
			</div>

			<div>
				<p className='text-white text-base text-center mt-4'>
					Don’t have an account?{' '}
					<Link to='/sign-up' className='text-purple mr-2'>
						Sign Up
					</Link>
				</p>
			</div>
		</form>
	);
}

export default SignInForm;
