import React from 'react';
import HomePageView from './HomePageView';

export const HomePageContainer = () => {
	return (
		<div className='bg-white'>
			<HomePageView />
		</div>
	);
};
