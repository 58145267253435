import React from 'react';
import DonateView from './DonateView';

export function DonateContainer() {
	return (
		<div>
			<DonateView />
		</div>
	);
}
