import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { WriteArticleForm } from 'components/layout/forms';
function WriteAnArticleView() {
	return (
		<div>
			<PageWrapper2>
				<div className='sm:px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 py-10 mx-6'>
					<WriteArticleForm />
				</div>
			</PageWrapper2>
		</div>
	);
}

export default WriteAnArticleView;
