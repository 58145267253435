import React from 'react';
import { PartnerWithUsIndividualForm } from 'components/layout/forms';
import { PageWrapper2 } from 'components/modules/Wrapper2';
// import { home } from 'assets/images';
function PartnerWithUsIndividualView() {
	return (
		<div className='bg-[#0E1022]'>
			<PageWrapper2>
				<div>
					<PartnerWithUsIndividualForm />
				</div>
			</PageWrapper2>
		</div>
	);
}

export default PartnerWithUsIndividualView;
