import React from 'react';
import { VolunteerForm } from 'components/layout/forms';
import { PageWrapper2 } from 'components/modules/Wrapper2';

function VolunteerView() {
	return (
		<div className='bg-[#0E1022]'>
			<PageWrapper2>
				<div>
					<VolunteerForm />
				</div>
			</PageWrapper2>
		</div>
	);
}

export default VolunteerView;
