import React from 'react';
import PartnersView from './PartnersView';

export function PartnersContainer() {
	return (
		<div>
			<PartnersView />
		</div>
	);
}
