import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Donate() {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			check: false,
			about: '',
			comment: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			lastName: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			email: Yup.string().email().required('Required'),
			phoneNumber: Yup.string().min(10).matches(phoneRegExp),
			check: Yup.bool()
				.required('Required')
				.oneOf([true], 'Accept the terms and conditions'),
			about: Yup.string().oneOf([
				'LinkedIn',
				'Social media',
				'Friends',
				'Referral',
				'Others',
			]),
			comment: Yup.string().max(50),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/form/donate',
				data: formik.values,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					console.log(response);
				})
				.catch(function (error) {
					console.log(error.config);
				});
		},
	});
	return (
		<form
			onSubmit={formik.handleSubmit}
			className='bg-[#0E1022] py-8 md:py-8 lg:px-8 lg:py-8 xl:px-8 xl:py-8 2xl:px-8 2xl:py-8 mb-0'
		>
			<h1 className='text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl text-center text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold pb-6 pt-4'>
				Donate
			</h1>
			<p className='text-base my-2 text-white text-center px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 pb-4 2xl:text-center'>
				Thank you for your interest in donating to help the Black Woman Can
				Organization. Kindly fill out this form to make your donation.
			</p>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='firstName' className='text-white text-base'>
						First Name <b className='text-red text-base'>*</b>
					</label>
					<input
						name='firstName'
						type='text'
						className='p-1 rounded w-full text-sm'
						placeholder='Enter your First Name'
						onChange={formik.handleChange}
					/>
					{formik.touched.firstName && formik.errors.firstName ? (
						<div className='text-red text-base'>{formik.errors.firstName}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='lastName' className='text-white text-base'>
						Last Name <b className='text-red text-base'>*</b>
					</label>
					<input
						name='lastName'
						type='text'
						className='p-1 rounded w-full text-sm'
						placeholder='Enter your Last Name'
						onChange={formik.handleChange}
					/>
					{formik.touched.lastName && formik.errors.lastName ? (
						<div className='text-red text-base'>{formik.errors.lastName}</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='email' className='text-white text-base'>
						Email Address <b className='text-red text-base'>*</b>
					</label>
					<input
						name='email'
						type='email'
						className='w-full p-1 rounded text-sm'
						placeholder='Enter your Email Address'
						onChange={formik.handleChange}
					/>
					{formik.touched.email && formik.errors.email ? (
						<div className='text-red text-base'>{formik.errors.email}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='phoneNumber' className='text-white text-base'>
						Phone Number
					</label>
					<input
						name='phoneNumber'
						type='tel'
						className='w-full p-1 rounded bg-white text-sm'
						placeholder='Enter your Phone Number'
						onChange={formik.handleChange}
					/>
					{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
						<div className='text-red text-base'>
							{formik.errors.phoneNumber}
						</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='about' className='text-white text-base'>
					How did you hear about us?
				</label>
				<select
					name='about'
					className='p-1 rounded text-sm'
					onChange={formik.handleChange}
				>
					<option>--Select--</option>
					<option value='LinkedIn'>LinkedIn</option>
					<option value='Social media'>Social media</option>
					<option value='Friends'>Friends</option>
					<option value='Referral'>Referral</option>
					<option value='Others'>Others</option>
				</select>
				{formik.touched.about && formik.errors.about ? (
					<div className='text-red text-base'>{formik.errors.about}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='comment' className='text-white text-base'>
					Leave a comment (Maximum 50 words)
				</label>
				<textarea
					name='comment'
					type='textarea'
					className='p-1 rounded text-sm'
					rows='8'
					placeholder='Enter your Comment (Max 50 words)'
					onChange={formik.handleChange}
				></textarea>
				{formik.touched.comment && formik.errors.comment ? (
					<div className='text-red text-base'>{formik.errors.comment}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='other' className='text-white text-base'>
					<input
						type='checkbox'
						name='other'
						className='bg-white rounded mr-2 text-justify text-sm'
						onChange={formik.handleChange}
					/>
					Remain Anonymous
				</label>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='check' className='text-white text-base'>
					<input
						type='checkbox'
						name='check'
						className='bg-white rounded mr-2 text-justify text-xs'
						onChange={formik.handleChange}
					/>
					By ticking this box, you agree to the{' '}
					<Link
						to='/terms-and-conditions'
						target='_blank'
						rel='noopener noreferrer'
						className='text-purple mr-2'
					>
						terms and conditions
					</Link>
					of our organization <b className='text-red text-base'>*</b>
				</label>
				{formik.touched.check && formik.errors.check ? (
					<div className='text-red text-base'>{formik.errors.check}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 pb-4'>
				<div></div>
				<div className='flex justify-center'>
					<button
						type='submit'
						className='w-32 h-12 text-base bg-purple text-white rounded'
					>
						Pay via Paypal
					</button>
				</div>
				<div></div>
			</div>
		</form>
	);
}

export default Donate;
