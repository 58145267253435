import React from 'react';

function TermsandConditions() {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	let monthIndex = new Date().getMonth();
	let monthName = monthNames[monthIndex];
	const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
	let dayIndex = new Date().getDay();
	let dayName = dayNames[dayIndex];
	return (
		<>
			<div className='py-4 px-6 mx-10 lg:py-10 lg:px-20 lg:mx-40'>
				<h2 className='text-center text-xl pt-6 pb-10 text-transparent font-bold bg-clip-text bg-gradient-to-r from-purple to-gold'>
					Current as of {dayName} {monthName} {new Date().getDate()}{' '}
					{new Date().getFullYear()}
				</h2>
				<div>
					<h1 className='text-center text-3xl pt-6 text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold'>
						Terms and conditions
					</h1>
				</div>
				<div>
					<p className='text-center text-base pt-6'>
						Your privacy is important to us at Black Woman Can. We respect your
						privacy regarding any information we may collect from you across our
						website.
					</p>
					<h2 className='text-center text-2xl pt-6 text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold'>
						Terms and Conditions for Black Woman Can
					</h2>
					<p className='text-justify text-base pt-6 pb-10'>
						Welcome to Black Woman Can (BWC), a non-profit organization
						dedicated to uplifting Black Women around the world through
						showcasing the achievements of Black Women throughout History,
						capturing their daily struggles for Justice and Equality. Debunking
						myths and challenging sterotypes. The following terms and conditions
						outline the rules and regulations for the use of our website and the
						services we offer.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						Use of Our Services
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						By using our website and the services we provide, you agree to be
						bound by these terms and conditions. If you do not agree to these
						terms, you are prohibited from using or accessing our website and
						services.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>Content</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						The content on our website and in our services is for general
						information purposes only. Black Woman Can makes no representations
						or warranties of any kind, express or implied, about the
						completeness, accuracy, reliability, suitability or availability
						with respect to the website or the information, products, services,
						or related graphics contained on the website for any purpose. Any
						reliance you place on such information is therefore strictly at your
						own risk.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						Limitations of Liability
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Black Woman Can will not be liable for any losses or damages arising
						from the use of our website or services. This includes, but is not
						limited to, indirect or consequential loss or damage, or any loss or
						damage whatsoever arising from loss of data or profits arising out
						of or in connection with the use of our website or services.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						External Links
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Our website may contain links to external websites. We have no
						control over the nature, content and availability of those sites.
						The inclusion of any links does not necessarily imply a
						recommendation or endorse the views expressed within them.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						Changes to Terms and Conditions
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Black Woman Can reserves the right to make changes to these terms
						and conditions at any time. Your continued use of our website and
						services following any changes indicates your acceptance of the new
						terms.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						Contact Us
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						If you have any questions or concerns about these terms and
						conditions, please contact us <b> info@blackwomancan.com</b>
					</p>
					<h2 className='text-justify text-xl pt-10 text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold'>
						Acceptance of Terms and Conditions
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						By using our website and services, you signify your acceptance of
						these terms and conditions. If you do not agree to these terms,
						please do not use our website or services
					</p>
				</div>
				<div>
					<h1 className='text-center text-xl pt-10 text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold'>
						Privacy Policy
					</h1>
				</div>
				<div>
					<p className='text-center text-base pt-6'>
						Your privacy is important to us at Black Woman Can. We respect your
						privacy regarding any information we may collect from you across our
						website.
					</p>
					<p className='text-justify text-base pt-10'>
						At Black Woman Can,&quot; we are committed to protecting the privacy
						of our donors, clients, and website visitors. This Privacy Policy
						applies to all personal information collected by &quot;Black Woman
						Can,&quot; both online and offline.
					</p>
					<p className='text-justify text-xl pt-10'>
						Personal Information Collection and Use
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						What information do we collect?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						We collect personal information from donors, clients, and website
						visitors through various means, including but not limited to:
						Donations made through our website or by mail Signing up for our
						newsletter or email updates Contacting us through our website or by
						email Participating in surveys or other research studies The
						personal information we collect may include: Name Contact
						information (email, phone number, mailing address) Demographic
						information (age, gender, race, ethnicity) Other information
						relevant to our mission and services.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						How do we use your information?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						At Black Woman Can, we take the privacy and security of your
						personal information seriously. We collect personal information from
						our users in order to operate our web application and provide
						services. Some of the ways in which we use your personal information
						include:
					</p>
					<p className='text-justify text-base pt-6 pb-4'>
						Processing donations and issuing receipts: We collect personal
						information such as name, email address, and payment information to
						process donations made to our organization and to issue receipts for
						tax purposes.
					</p>
					<p className='text-justify text-base'>
						Sending newsletters, updates, and other communications: We use your
						email address to send you newsletters, updates, and other
						communications about our organization and services. You can opt-out
						of these communications at any time.
					</p>
					<p className='text-justify text-base'>
						Responding to inquiries and requests for information: We use your
						personal information to respond to your inquiries and requests for
						information about our organization and services. Conducting research
						and analysis to improve our services: We may use your personal
						information to conduct research and analysis to improve our services
						and better understand our users&apos; needs.
					</p>
					<p className='text-justify text-base'>
						Connecting users with Black Woman Can providers: Our web application
						connects users who need services with Black Woman Can providers. We
						use your personal information to match you with a Black Woman Can
						provider that meets your needs.
					</p>
					<p className='text-justify text-base'>
						Complying with legal and regulatory requirements: We may be required
						to disclose your personal information in response to a subpoena,
						court order, or other legal or regulatory request.
					</p>
					<p className='text-justify text-base'>
						Please note that we only collect and use personal information that
						is necessary for the purposes outlined above, and we take steps to
						protect the security and confidentiality of your personal
						information. If you have any questions or concerns about how we use
						your personal information, please contact us.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						Do we use cookies and other tracking technologies?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Disclosure of Personal Information . <br />
						<br /> We will not sell, share, or otherwise disclose personal
						information to any third party, except as required by law or to
						protect the rights, property, or safety of &quot;Black Woman
						Can,&quot; our donors, clients, or others
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						How long do we keep your information?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Changes to This Policy. <br />
						<br /> We reserve the right to modify this Privacy Policy at any
						time. If we make changes to this policy, we will update this page
						and indicate the date of the latest revision.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						How do we keep your information safe?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Security Measures. <br />
						<br /> We take appropriate security measures to protect personal
						information from unauthorized access, alteration, disclosure, or
						destruction. However, please be aware that no method of transmission
						over the internet or method of electronic storage is completely
						secure.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						What are your privacy rights?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Pharetra morbi libero id aliquam elit massa integer tellus. Quis
						felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit
						dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac
						pellentesque ac.
					</p>
					<h2 className='text-justify text-xl pt-10 font-semibold'>
						How can you contact us about this policy?
					</h2>
					<p className='text-justify text-base pt-6 pb-4'>
						Contact Us <br />
						<br /> If you have any questions or concerns about this Privacy
						Policy or our handling of personal information, please contact us at
						<b> info@blackwomancan.com</b>
					</p>
				</div>
			</div>
		</>
	);
}

export default TermsandConditions;
