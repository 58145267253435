import { logo, signuphead } from 'assets/images';
import React from 'react';
import { Link } from 'react-router-dom';

const SignUpBackground = () => {
	return (
		<div>
			<div className='flex justify-center items-center pt-8'>
				<img className='object-contain w-12 h-12' src={logo} alt='logo' />
				<p className='ml-4 font-pacifico text-2xl'>
					<Link to='/' className='text-black ml-4 font-pacifico text-xl'>
						Black Woman Can
					</Link>
				</p>
			</div>

			<div>
				<div className='pb-6 mt-10 flex flex-row justify-center'>
					<img className='mx-auto' src={signuphead} alt='sign up head image' />
				</div>
				<div className='text-center'>
					<p className='text-xl'>Black Woman Can!</p>
					<p className='text-xl'>Black Woman Has Done It!</p>
					<p className='text-xl'>Black Woman Making It!</p>
				</div>
			</div>
		</div>
	);
};

export default SignUpBackground;
