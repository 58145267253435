import { React, useState } from 'react';
import { ProjectData } from 'Data';

//This is the project section under the project page
const ProjectSection = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const postsPerPage = 4;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = ProjectData.slice(indexOfFirstPost, indexOfLastPost);
	const npage = Math.ceil(ProjectData.length / postsPerPage);
	const numbers = [...Array(npage + 1).keys()].slice(1);

	return (
		<>
			<div>
				{currentPosts.map((project, id) => (
					<div
						className='mt-12 mb-4 items-center flex flex-col gap-y-4'
						key={id}
					>
						<h1 className='font-bold text-xl'>{project.title}</h1>
						<img src={project.image} alt={project.title} className='w-full' />
						<p className='text-sm'>{project.body}</p>
					</div>
				))}
			</div>
			{/* pagination section */}
			<div className='grid grid-cols-3 text-sm mt-6'>
				<div></div>
				<div className='flex items-center font-medium gap-x-2'>
					{numbers.map((n, i) => (
						<a
							key={i}
							onClick={() => changeCPage(n)}
							href='#'
							className={`page-item ${
								currentPage === n ? 'active' : ''
							}border-solid border-[#000] border-2 px-4 py-2`}
						>
							{n}
						</a>
					))}
				</div>
				<div></div>
			</div>
		</>
	);
	function changeCPage(id) {
		setCurrentPage(id);
	}
};

export default ProjectSection;
