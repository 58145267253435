import { thumb, message } from 'assets/images';
import { useState, React } from 'react';
import { VideoData } from 'Data';

const Videos = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const postsPerPage = 4;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = VideoData.slice(indexOfFirstPost, indexOfLastPost);
	const npage = Math.ceil(VideoData.length / postsPerPage);
	const numbers = [...Array(npage + 1).keys()].slice(1);

	return (
		<>
			<div>
				{currentPosts.map((video, id) => (
					<div key={id} className='mt-10'>
						<div className='border-2 border-purple border-solid'>
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-b from-purple to-gold text-xl px-4 md:px-4 lg:px-10 xl:px-10 2xl:px-10 pt-10'>
								{video.title}
							</h1>
							<p className='text-grey text-base px-4 md:px-4 lg:px-10 xl:px-10 2xl:px-10'>
								{video.date}
							</p>
							<video
								src={video.video}
								controls='controls'
								controlsList='nodownload'
								className='mx-auto w-full px-4 md:px-4 lg:px-10 xl:px-10 2xl:px-10 py-10'
							/>
							<div className='flex justify-center gap-x-2 px-0'>
								<button className='py-4 text-base bg-black text-white mt-4 w-full flex justify-center gap-x-4 items-center'>
									<img src={thumb} alt={thumb} width={20} />
									Like
								</button>
								<button className='py-4 text-base bg-black text-white mt-4 w-full flex justify-center gap-x-4 items-center'>
									<img src={message} alt={thumb} width={20} />
									Comment
								</button>
							</div>
						</div>
					</div>
				))}
			</div>

			{/* pagination section */}
			<div className='grid grid-cols-3 text-sm mt-6'>
				<div></div>
				<div className='flex items-center font-medium gap-x-2'>
					{numbers.map((n, i) => (
						<a
							key={i}
							onClick={() => changeCPage(n)}
							href='#'
							className={`page-item ${
								currentPage === n ? 'active' : ''
							}border-solid border-[#000] border-2 px-4 py-2`}
						>
							{n}
						</a>
					))}
				</div>
				<div></div>
			</div>
		</>
	);
	function changeCPage(id) {
		setCurrentPage(id);
	}
};

export default Videos;
