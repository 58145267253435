import { React } from 'react';
import { Comment } from 'components/widgets/Comments';
import { LikeButton } from 'components/widgets/Buttons';
import { CommentForm } from 'components/layout/forms';
import { ProgramData } from 'Data';
import { useParams } from 'react-router-dom';

//This is the program single page section under the program page
const ProgramSingle = () => {
	const { title } = useParams();
	return (
		<>
			{ProgramData.filter((desc) => desc.title === title).map((Single, id) => (
				<div key={id}>
					{/* The image banner section */}
					<div className='mt-8'>
						<video
							src={Single.video}
							controls='controls'
							controlsList='nodownload'
							className='w-full'
						/>
					</div>
					{/* The title and view section */}
					<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 mt-4 gap-y-2'>
						<div>
							<h1 className='font-semibold text-xl'>{Single.title}</h1>
						</div>
						<div>{/* The second row */}</div>
						<div>
							<p className='text-lg font-medium md:text-right lg:text-right xl:text-right 2xl:text-right'>
								1,100,285 Views
							</p>
						</div>
					</div>
					{/* The button and likes section */}
					<LikeButton />
					{/* The comment section */}
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-xl mt-[30px]'>
						Comments(20)
					</h1>
					<Comment />
					{/* The Leave a comment Form section */}
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-xl mt-[30px]'>
						Leave a comment
					</h1>
					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 my-8'>
						<CommentForm />
					</div>
				</div>
			))}
		</>
	);
};

export default ProgramSingle;
