import React, { memo } from 'react';
import { TeamMembersData } from 'Data';

function OurTeamSection() {
	const currentPage = 1;
	const postsPerPage = 3;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = TeamMembersData.slice(indexOfFirstPost, indexOfLastPost);

	return (
		<>
			{currentPosts.map((member, id) => (
				<div key={id}>
					<div>
						<img
							src={member.image}
							alt={member.name}
							className='w-70% rounded-full border-4 border-secondary border-solid mx-auto'
						/>
						<div className='text-lg pt-6'>
							<p className='font-bold text-center'>{member.name}</p>
							<p className='font-normal text-center'>{member.title}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
}

export default memo(OurTeamSection);
