import React from 'react';
import { arrow } from 'assets/images';
import SocialMediaButton from './SocialMediaButton';

function ArrowButton(props) {
	return (
		<SocialMediaButton imageUrl={arrow} {...props} to='' alt='Arrow Icon' />
	);
}

export default ArrowButton;
