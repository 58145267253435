import React from 'react';
import SignUpView from './SignUpView';

export function SignUpContainer() {
	return (
		<div>
			<SignUpView />
		</div>
	);
}
