import { React } from 'react';
import { blogsingle } from 'assets/images';
import { LikeButton } from 'components/widgets/Buttons';
import { CommentForm } from 'components/layout/forms';
import { BlogData } from 'Data';
import { BlogRecent } from '.';
import { useParams } from 'react-router-dom';
import { Comment } from 'components/widgets/Comments';

//This is the blog single page section under the blog page
const BlogSingle = () => {
	const { title } = useParams();
	return (
		<>
			{BlogData.filter((desc) => desc.title === title).map((blog, id) => (
				<div key={id}>
					{/* The image,title and author banner section */}
					<div>
						<h1 className='font-semibold text-2xl text-center'>{blog.title}</h1>
						<h6 className='font-semibold text-[#7A7B74] text-sm text-center pt-4'>
							Published on {blog.date}
						</h6>
						<div>
							<div className='flex items-center justify-center pt-4 pb-10 gap-x-4'>
								<div>
									<img
										src={blog.writerImage}
										alt={blog.writerName}
										width={40}
									/>
								</div>
								<div>
									<p className='font-bold text-xs'>{blog.writerName}</p>
									<p className='text-[#7A7B74] text-xs'>{blog.WriterTitle}</p>
								</div>
							</div>
						</div>
						<img
							src={blogsingle}
							alt={blogsingle}
							className='mx-auto w-full h-full'
						/>
					</div>
					<div className='flex flex-col lg:flex-row xl:flex-row 2xl:flex-row py-10 text-justify gap-10'>
						<div className='lg:w-[70%] xl:w-[70%] 2xl:w-[70%]'>
							<p className='text-base'>
								Black Woman Can is an international movement to uplift Black
								Women around the world through showcasing the achievements of
								Black Women throughout History, capturing their daily struggles
								for Justice and Equality, debunking myths and challenging
								stereotypes, helping Black Women discover themselves, promoting
								natural beauty and encouraging Black women to embrace their
								culture and true identity, nurture and mentor young Black girls
								to become great leaders, providing a platform to share
								Inspiring, motivational stories of experiences of Black Women
								who broke the barriers and made History.
							</p>
							<p className='pt-6 text-base'>
								The upcoming show will provide a platform to uplift Black Women
								around the world and inspire them to love themselves for who
								they are without apology or asking permission to exist,
								acknowledge their full worth, reclaim their dignity and enable
								them reach their full potential.
							</p>
							<img src={blogsingle} alt={blog.title} className='pt-6' />
							<p className='pt-6 text-base'>
								Amidst racism and global anti-blackness the Black woman
								continues to be sidelined and has to work thrice as hard, be
								three times more qualified in order to get the same kind of
								recognition as a white woman. It has not been easy for Black
								women to ascend into positions of authority be it in corporate
								world or in politics but in spite of all odds we have seen Black
								women like Judge Ketanji Brown who became the first black woman
								to become a justice on the US Supreme Court in its 233-year
								history. Black Woman Can goal is to uplift Black Women around
								the world and celebrate their achievements that for many years
								have been overlooked or hidden. Black Woman Can, Black Woman has
								done it, Black Woman Making it.
							</p>
							{/* The button and likes section */}
							<LikeButton />
						</div>
						<div className='lg:w-[30%] xl:w-[30%] 2xl:w-[30%]'>
							{/* The Recent Articles section */}
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
								Related Articles
							</h1>
							<BlogRecent />
							{/* The comment section */}
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl pt-4'>
								Comments
							</h1>
							<h6 className='font-semibold text-[#7A7B74] text-lg'>
								10 Comments
							</h6>
							<Comment />
							{/* The Leave a comment Form section */}
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl mt-40px pb-8'>
								Leave a comment
							</h1>
							<CommentForm />
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default BlogSingle;
