import React from 'react';
import SignInForm from 'components/layout/forms/signinForm';
import SignInBackground from './SignInBackground';
function SignInView() {
	return (
		<div className='flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row h-full'>
			<div className='md:w-[40%] lg:w-[40%] xl:w-[40%] 2xl:w-[40%] py-10 bg-gradient-to-b from-purple via-violet via-violet to-gold'>
				<SignInBackground />
			</div>
			<div className='md:w-[60%] lg:w-[60%] xl:w-[60%] 2xl:w-[60%] p-10 md:p-4 lg:p-0 xl:p-0 2xl:p-0 bg-black'>
				<SignInForm />
			</div>
		</div>
	);
}

export default SignInView;
