import React from 'react';
import ProgramArticlesView from './ProgramArticlesView';

export function ProgramArticlesContainer() {
	return (
		<div>
			<ProgramArticlesView />
		</div>
	);
}
