import React from 'react';
// import { AboutLeft, AboutRight, AboutBackground } from 'assets/images';
// import { Link } from 'react-router-dom';
import { bwcMission, bwcObjective, bwcVision } from 'assets/images';

function MissionVision() {
	return (
		<>
			{' '}
			<div
				className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-y-6'
				id='vision'
			>
				<div className='flex flex-col justify-center'>
					<img src={bwcVision} className='w-80% mx-auto' alt='Vision Image' />
				</div>
				<div className='flex flex-col justify-center'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-80 text-2xl'>
						Our Vision
					</h1>
					<p className='text-sm my-2 text-justify'>
						To inspire, Empower, and uplift Black Women around the world.
					</p>
				</div>
			</div>
			<div
				className='flex flex-col-reverse md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-y-6'
				id='mission'
			>
				<div className='flex flex-col justify-center'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-80 text-2xl'>
						Our Mission
					</h1>
					<p className='text-sm my-2 text-justify'>
						To showcase and celebrate the achievements of Black Women throughout
						History.
					</p>
				</div>
				<div className='flex flex-col justify-center'>
					<img src={bwcMission} className='w-80% mx-auto' alt='Mission Image' />
				</div>
			</div>
			<div
				className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'
				id='objectives'
			>
				<div className='flex flex-col justify-center'>
					<img
						src={bwcObjective}
						className='w-80% mx-auto'
						alt='Objectives Image'
					/>
				</div>
				<div className='flex flex-col justify-center'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-80 text-2xl'>
						Our Objectives
					</h1>
					<p className='text-sm my-2 text-justify'>
						<ul>
							<li>
								To inspire Black Women to acknowledge their full worth and reach
								their full potential.
							</li>
							<li>
								To celebrate and acknowledge the enormous contributions of Black
								Women throughout History.
							</li>
							<li>
								To create role models, and mentor young Black Girls to embrace
								their Black Identity and pride.
							</li>
							<li>
								To empower Black Women socially, culturally, and economically
								and encourage Black Women&apos;s leadership.
							</li>
							<li>
								To debunk myths about Black Women and provide a platform for
								Black Women to share their stories, and motivate and inspire the
								world.
							</li>
							<li>
								To encourage Black Women to embrace their natural beauty and
								combat natural hair discrimination.
							</li>
						</ul>
					</p>
				</div>
			</div>
		</>
	);
}

export default MissionVision;
