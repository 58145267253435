import React from 'react';
import TeamView from './TeamView';

export function TeamContainer() {
	return (
		<div>
			<TeamView />
		</div>
	);
}
