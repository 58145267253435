import React from 'react';
import WriteAnArticleView from './WriteAnArticleView';

export function WriteAnArticleContainer() {
	return (
		<div>
			<WriteAnArticleView />
		</div>
	);
}
