import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { BlogSingle } from 'components/layout/Blog';

function BlogArticleView() {
	return (
		<PageWrapper2>
			<div className='px-10 md:px-10 lg:px-20 xl:px-20 2xl:px-20 py-4'>
				<div>
					<BlogSingle />
				</div>
			</div>
		</PageWrapper2>
	);
}

export default BlogArticleView;
