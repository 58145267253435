import React from 'react';
import VolunteerView from './VolunteerView';

export function VolunteerContainer() {
	return (
		<div>
			<VolunteerView />
		</div>
	);
}
