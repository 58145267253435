import React from 'react';
// import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function CommentForm() {
	return (
		<Formik
			initialValues={{
				comment: '',
			}}
			validationSchema={Yup.object({
				comment: Yup.string()
					.required('Required')
					.max(50, 'Must be 50 characters or less'),
			})}
			onSubmit={(values) => {
				axios({
					method: 'POST',
					url: 'http://localhost:1337/api/auth/register',
					data: values,
					headers: { 'Content-Type': 'application/json' },
				})
					.then(function (response) {
						console.log(response);
						alert('New User Successfully Added.');
					})
					.catch(function (error) {
						if (error.response) {
							// The request was made and the server responded with a status code
							// that falls out of the range of 2xx
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
							alert('User Already exists.');
						} else if (error.request) {
							// The request was made but no response was received
							// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
							// http.ClientRequest in node.js
							console.log(error.request);
						} else {
							// Something happened in setting up the request that triggered an Error
							console.log('Error', error.message);
						}
						console.log(error.config);
					});
			}}
		>
			<Form className='border-black'>
				<div>
					<div>
						<Field
							name='comment'
							as='textarea'
							className='p-1 rounded border-black border-solid border-2 w-[100%] text-sm'
							rows='4'
							placeholder='Comment'
						/>
						<ErrorMessage name='comment'>
							{(msg) => (
								<div className='text-red text-base font-bold'>{msg}</div>
							)}
						</ErrorMessage>
					</div>
					<div className='flex pt-6'>
						<button
							type='submit'
							className='w-32 h-10 text-base bg-purple text-white rounded'
						>
							Post comment
						</button>
					</div>
				</div>
			</Form>
		</Formik>
	);
}

export default CommentForm;
