const IMAGES_PATH = '/assets/images';

export const bwcMission = `${IMAGES_PATH}/bwcMission.png`;
export const home = `${IMAGES_PATH}/home.jpg`;
export const bwc = `${IMAGES_PATH}/bwc.jpeg`;
export const logo = `${IMAGES_PATH}/logo.png`;
export const backgroundImage = `${IMAGES_PATH}/svg/bwc.svg`;
export const programsarticle = `${IMAGES_PATH}/programsarticle.png`;
export const thumb = `${IMAGES_PATH}/thumb.png`;
export const circle = `${IMAGES_PATH}/circle.png`;
export const slider1 = `${IMAGES_PATH}/slider1.png`;
export const slider2 = `${IMAGES_PATH}/slider2.png`;
export const slider3 = `${IMAGES_PATH}/slider3.png`;
export const slider4 = `${IMAGES_PATH}/slider4.png`;
export const slider5 = `${IMAGES_PATH}/slider5.png`;
export const bwcVision = `${IMAGES_PATH}/bwcVision.png`;
export const bwcObjective = `${IMAGES_PATH}/bwcObjective.png`;
export const usaf = `${IMAGES_PATH}/usaf.png`;
export const twtlogo = `${IMAGES_PATH}/twtlogo.jpg`;
export const gpan = `${IMAGES_PATH}/gpan.jpeg`;
export const daniel = `${IMAGES_PATH}/daniel.png`;
export const adamu = `${IMAGES_PATH}/adamu.png`;
export const maureen = `${IMAGES_PATH}/maureen.png`;
export const signuphead = `${IMAGES_PATH}/signuphead.png`;
export const signinhead = `${IMAGES_PATH}/signinhead.png`;
export const programs = `${IMAGES_PATH}/programs.png`;
export const programs1 = `${IMAGES_PATH}/programs1.png`;
export const AboutLeft = `${IMAGES_PATH}/AboutLeft.png`;
export const AboutRight = `${IMAGES_PATH}/AboutRight.png`;
export const AboutBackground = `${IMAGES_PATH}/AboutBackground.png`;
export const BoxBackground = `${IMAGES_PATH}/BoxBackground.png`;
export const location = `${IMAGES_PATH}/location.png`;
export const email = `${IMAGES_PATH}/email.png`;
export const arrow = `${IMAGES_PATH}/arrow.png`;
export const project = `${IMAGES_PATH}/project.png`;
export const project1 = `${IMAGES_PATH}/project1.png`;
export const blog1 = `${IMAGES_PATH}/blog1.png`;
export const blog2 = `${IMAGES_PATH}/blog2.png`;
export const blogsingle = `${IMAGES_PATH}/blogsingle.png`;
export const partner = `${IMAGES_PATH}/partner.png`;
export const partner1 = `${IMAGES_PATH}/partner1.png`;
export const partner2 = `${IMAGES_PATH}/partner2.png`;
export const partner3 = `${IMAGES_PATH}/partner3.png`;
export const partner4 = `${IMAGES_PATH}/partner4.png`;
export const partner5 = `${IMAGES_PATH}/partner5.png`;
export const partner6 = `${IMAGES_PATH}/partner6.png`;
export const partner7 = `${IMAGES_PATH}/partner7.png`;
export const partner8 = `${IMAGES_PATH}/partner8.png`;
export const partner9 = `${IMAGES_PATH}/partner9.png`;
export const partner10 = `${IMAGES_PATH}/partner10.png`;
export const partner11 = `${IMAGES_PATH}/partner11.png`;
export const partner12 = `${IMAGES_PATH}/partner12.png`;
export const success = `${IMAGES_PATH}/success.png`;
export const menu = `${IMAGES_PATH}/menu.png`;
export const cancel = `${IMAGES_PATH}/cancel.png`;
export const message = `${IMAGES_PATH}/message.png`;
