import React from 'react';
import ProjectView from './ProjectView';

export function ProjectContainer() {
	return (
		<div>
			<ProjectView />
		</div>
	);
}
