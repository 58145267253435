import React from 'react';
import SignUpBackground from './SignUpBackground';
import SignUpForm from 'components/layout/forms/signupForm';
function SignUpView() {
	return (
		<div className='flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row h-full'>
			<div className='md:w-[40%] lg:w-[40%] xl:w-[40%] 2xl:w-[40%] py-10 bg-gradient-to-b from-purple via-violet via-violet to-gold'>
				<SignUpBackground />
			</div>
			<div className='md:w-[60%] lg:w-[60%] xl:w-[60%] 2xl:w-[60%] p-10 md:p-4 lg:p-0 xl:p-0 2xl:p-0 bg-black'>
				<SignUpForm />
			</div>
		</div>
	);
}

export default SignUpView;
