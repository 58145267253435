import {
	partner,
	partner1,
	partner10,
	partner11,
	partner12,
	partner2,
	partner3,
	partner4,
	partner5,
	partner6,
	partner7,
	partner8,
	partner9,
} from 'assets/images';

const Partner = [
	{
		id: 1,
		image: partner,
	},
	{
		id: 2,
		image: partner1,
	},
	{
		id: 3,
		image: partner2,
	},
	{
		id: 4,
		image: partner3,
	},
	{
		id: 5,
		image: partner4,
	},
	{
		id: 6,
		image: partner5,
	},
	{
		id: 7,
		image: partner6,
	},
	{
		id: 8,
		image: partner7,
	},
	{
		id: 9,
		image: partner8,
	},
	{
		id: 10,
		image: partner9,
	},
	{
		id: 11,
		image: partner10,
	},
	{
		id: 12,
		image: partner11,
	},
	{
		id: 13,
		image: partner12,
	},
];

export default Partner;
