import React from 'react';
import ProgramsView from './ProgramsView';

export function ProgramsContainer() {
	return (
		<div>
			<ProgramsView />
		</div>
	);
}
