import React from 'react';
import ReadArticlesView from './ReadArticlesView';

export function ReadArticlesContainer() {
	return (
		<div>
			<ReadArticlesView />
		</div>
	);
}
