import React from 'react';

function Copyright() {
	return (
		<>
			<div className='text-white grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 w-full'>
				<div>
					<p className='text-white font-semibold md:text-center lg:text-left text-xs'>
						{`© Copyright ${new Date().getFullYear()} BWC All rights reserved.`}
					</p>
				</div>
				<div></div>
				<div></div>
			</div>
		</>
	);
}

export default Copyright;
