import { slider1, slider2, slider3, slider4, slider5 } from 'assets/images';

const Slides = [
	{
		id: 1,
		image: slider1,
	},
	{
		id: 2,
		image: slider2,
	},
	{
		id: 3,
		image: slider3,
	},
	{
		id: 4,
		image: slider4,
	},
	{
		id: 5,
		image: slider5,
	},
];

export default Slides;
