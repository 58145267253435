import { React } from 'react';
import { BlogData } from 'Data';
import { Link } from 'react-router-dom';

//This is the ongoing event section under the announcement page
const Blog = () => {
	const currentPage = 1;
	const postsPerPage = 1;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = BlogData.slice(indexOfFirstPost, indexOfLastPost);

	return (
		<>
			{currentPosts.map((blog, id) => (
				<div
					key={id}
					className='flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row pt-8 gap-x-4 justify-center items-center'
				>
					<div className='lg:w-[30%] xl:w-[30%] 2xl:w-[30%]'>
						<img src={blog.image} alt={blog.title} className='w-full mb-4' />
					</div>
					<div className='lg:w-[70%] xl:w-[70%] 2xl:w-[70%]'>
						<h6 className='font-semibold text-[#7A7B74] text-sm'>
							{blog.date}
						</h6>
						<h2 className='font-bold text-[#000000] text-lg pt-4'>
							{blog.title}
						</h2>
						<p className='pt-4 text-sm text-justify'>
							{blog.body.substring(0, 260)}...
							<Link
								to={`/blog-article/${blog.title}`}
								className='text-[#952B95] font-bold text-sm'
							>
								Read more
							</Link>
						</p>
						<div className='flex pt-8 justify-between'>
							<div className='flex gap-x-4'>
								<div>
									<img
										src={blog.writerImage}
										alt={blog.WriterTitle}
										width={40}
									/>
								</div>
								<div>
									<p className='font-bold text-xs'>{blog.writerName}</p>
									<p className='text-xs'>{blog.WriterTitle}</p>
								</div>
							</div>
							<div>
								<p className='font-bold text-xs'>{blog.views} Reads</p>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default Blog;
