import React from 'react';
import AnnouncementView from './AnnouncementView';

export function AnnouncementContainer() {
	return (
		<div>
			<AnnouncementView />
		</div>
	);
}
