const article = [
	{
		id: 1,
		title: 'Empowering The Girl Child',
		paragraph:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna eu enim ut consectetur viverra commodo, egestas in. Quam nam velit dui aliquam in nunc id. Eleifend consectetur varius egestas quam scelerisque integer dictum at. Dictum volutpat id nulla tempor fermentum. Lacus in sapien et gravida netus massa placerat pulvinar dui. Dignissim venenatis interdum ac amet eget tempus, dolor vitae. Magna elementum et tincidunt odio nibh. Amet et, enim, placerat diam lectus bibendum id. Porta nascetur ac ornare vitae, eu id mattis egestas. Velit blandit volutpat eget aliquam neque purus. Dui risus, massa elit aliquam viverra. Viverra tristique tempor vitae tellus consectetur sit eget eget massa. Ut egestas velit morbi at. Sit pellentesque augue euismod purus nulla etiam eu. Sapien ridiculus nunc, vitae cursus eros, nulla risus. Aenean molestie elementum urna, sagittis nisl sit hac. Enim blandit amet lacus, faucibus non tortor sodales. Mauris sed sagittis eget adipiscing accumsan neque. Placerat enim ultricies scelerisque ipsum molestie odio. Facilisi integer aliquam nulla egestas quam in. Quam euismod tellus ac pretium venenatis. Libero duis sed pretium morbi elit est  mattis maecenas nulla mattis dictumst id diam faucibus. Elementum morbi nunc molestie vitae, lacus. Eu sit felis amet cras ac cursus proin.',
		date: '2 days ago',
		author: 'Adewale Sophia ',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		paragraph:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna eu enim ut consectetur viverra commodo, egestas in. Quam nam velit dui aliquam in nunc id. Eleifend consectetur varius egestas quam scelerisque integer dictum at. Dictum volutpat id nulla tempor fermentum. Lacus in sapien et gravida netus massa placerat pulvinar dui. Dignissim venenatis interdum ac amet eget tempus, dolor vitae. Magna elementum et tincidunt odio nibh. Amet et, enim, placerat diam lectus bibendum id. Porta nascetur ac ornare vitae, eu id mattis egestas. Velit blandit volutpat eget aliquam neque purus. Dui risus, massa elit aliquam viverra. Viverra tristique tempor vitae tellus consectetur sit eget eget massa. Ut egestas velit morbi at. Sit pellentesque augue euismod purus nulla etiam eu. Sapien ridiculus nunc, vitae cursus eros, nulla risus. Aenean molestie elementum urna, sagittis nisl sit hac. Enim blandit amet lacus, faucibus non tortor sodales. Mauris sed sagittis eget adipiscing accumsan neque. Placerat enim ultricies scelerisque ipsum molestie odio. Facilisi integer aliquam nulla egestas quam in. Quam euismod tellus ac pretium venenatis. Libero duis sed pretium morbi elit est  mattis maecenas nulla mattis dictumst id diam faucibus. Elementum morbi nunc molestie vitae, lacus. Eu sit felis amet cras ac cursus proin.',
		date: '2 days ago',
		author: 'Adewale Sophia ',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		paragraph:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna eu enim ut consectetur viverra commodo, egestas in. Quam nam velit dui aliquam in nunc id. Eleifend consectetur varius egestas quam scelerisque integer dictum at. Dictum volutpat id nulla tempor fermentum. Lacus in sapien et gravida netus massa placerat pulvinar dui. Dignissim venenatis interdum ac amet eget tempus, dolor vitae. Magna elementum et tincidunt odio nibh. Amet et, enim, placerat diam lectus bibendum id. Porta nascetur ac ornare vitae, eu id mattis egestas. Velit blandit volutpat eget aliquam neque purus. Dui risus, massa elit aliquam viverra. Viverra tristique tempor vitae tellus consectetur sit eget eget massa. Ut egestas velit morbi at. Sit pellentesque augue euismod purus nulla etiam eu. Sapien ridiculus nunc, vitae cursus eros, nulla risus. Aenean molestie elementum urna, sagittis nisl sit hac. Enim blandit amet lacus, faucibus non tortor sodales. Mauris sed sagittis eget adipiscing accumsan neque. Placerat enim ultricies scelerisque ipsum molestie odio. Facilisi integer aliquam nulla egestas quam in. Quam euismod tellus ac pretium venenatis. Libero duis sed pretium morbi elit est  mattis maecenas nulla mattis dictumst id diam faucibus. Elementum morbi nunc molestie vitae, lacus. Eu sit felis amet cras ac cursus proin.',
		date: '2 days ago',
		author: 'Adewale Sophia ',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		paragraph:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna eu enim ut consectetur viverra commodo, egestas in. Quam nam velit dui aliquam in nunc id. Eleifend consectetur varius egestas quam scelerisque integer dictum at. Dictum volutpat id nulla tempor fermentum. Lacus in sapien et gravida netus massa placerat pulvinar dui. Dignissim venenatis interdum ac amet eget tempus, dolor vitae. Magna elementum et tincidunt odio nibh. Amet et, enim, placerat diam lectus bibendum id. Porta nascetur ac ornare vitae, eu id mattis egestas. Velit blandit volutpat eget aliquam neque purus. Dui risus, massa elit aliquam viverra. Viverra tristique tempor vitae tellus consectetur sit eget eget massa. Ut egestas velit morbi at. Sit pellentesque augue euismod purus nulla etiam eu. Sapien ridiculus nunc, vitae cursus eros, nulla risus. Aenean molestie elementum urna, sagittis nisl sit hac. Enim blandit amet lacus, faucibus non tortor sodales. Mauris sed sagittis eget adipiscing accumsan neque. Placerat enim ultricies scelerisque ipsum molestie odio. Facilisi integer aliquam nulla egestas quam in. Quam euismod tellus ac pretium venenatis. Libero duis sed pretium morbi elit est  mattis maecenas nulla mattis dictumst id diam faucibus. Elementum morbi nunc molestie vitae, lacus. Eu sit felis amet cras ac cursus proin.',
		date: '2 days ago',
		author: 'Adewale Sophia ',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		paragraph:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna eu enim ut consectetur viverra commodo, egestas in. Quam nam velit dui aliquam in nunc id. Eleifend consectetur varius egestas quam scelerisque integer dictum at. Dictum volutpat id nulla tempor fermentum. Lacus in sapien et gravida netus massa placerat pulvinar dui. Dignissim venenatis interdum ac amet eget tempus, dolor vitae. Magna elementum et tincidunt odio nibh. Amet et, enim, placerat diam lectus bibendum id. Porta nascetur ac ornare vitae, eu id mattis egestas. Velit blandit volutpat eget aliquam neque purus. Dui risus, massa elit aliquam viverra. Viverra tristique tempor vitae tellus consectetur sit eget eget massa. Ut egestas velit morbi at. Sit pellentesque augue euismod purus nulla etiam eu. Sapien ridiculus nunc, vitae cursus eros, nulla risus. Aenean molestie elementum urna, sagittis nisl sit hac. Enim blandit amet lacus, faucibus non tortor sodales. Mauris sed sagittis eget adipiscing accumsan neque. Placerat enim ultricies scelerisque ipsum molestie odio. Facilisi integer aliquam nulla egestas quam in. Quam euismod tellus ac pretium venenatis. Libero duis sed pretium morbi elit est  mattis maecenas nulla mattis dictumst id diam faucibus. Elementum morbi nunc molestie vitae, lacus. Eu sit felis amet cras ac cursus proin.',
		date: '2 days ago',
		author: 'Adewale Sophia ',
	},
];

export default article;
