import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { UploadVideoForm } from 'components/layout/forms';
function UploadAVideoView() {
	return (
		<div>
			<PageWrapper2>
				<div className='px-4 md:px-20 lg:px-20 xl:px-20 2xl:px-20 py-10 mx-6'>
					<UploadVideoForm />
				</div>
			</PageWrapper2>
		</div>
	);
}

export default UploadAVideoView;
