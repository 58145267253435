import { React } from 'react';
import { TeamMembersData } from 'Data';

//This is the team members section under the About Us page
const TeamMembers = () => {
	return (
		<>
			{TeamMembersData.map((member, id) => (
				<div key={id}>
					<div>
						<img
							src={member.image}
							alt={member.name}
							className='w-70% rounded-full border-4 border-secondary border-solid mx-auto'
						/>
						<div className='text-lg pt-6'>
							<p className='font-bold text-center'>{member.name}</p>
							<p className='font-normal text-center'>{member.title}</p>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default TeamMembers;
