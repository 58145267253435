import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { success } from 'assets/images';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Volunteer() {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			linkedInUrl: '',
			phoneNumber: '',
			attachCv: '',
			headShot: '',
			biography: '',
			checkbox: false,
		},
		validationSchema: Yup.object({
			firstName: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			lastName: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			linkedInUrl: Yup.string(),
			email: Yup.string().email().required('Required'),
			phoneNumber: Yup.string().min(10).matches(phoneRegExp),
			attachCv: Yup.mixed().required('Required'),
			headShot: Yup.mixed().required('Required'),
			biography: Yup.string()
				.max(50, 'Must be 50 characters or less')
				.required('Required'),
			checkbox: Yup.bool()
				.required('Required')
				.oneOf([true], 'Accept the terms and conditions'),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/form/content-creator',
				data: formik.values,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function () {
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submitted Successfully',
						text: 'You have successfully filled the Content Creator Form.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
					navigate('/content-creator');
				})
				.catch(function () {
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submission was Unsuccessful',
						text: 'Kindly try again.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
					navigate('/content-creator');
				});
		},
	});
	return (
		<form
			onSubmit={formik.handleSubmit}
			className='bg-[#0E1022] py-8 md:py-8 lg:px-8 lg:py-8 xl:px-8 xl:py-8 2xl:px-8 2xl:py-8 mb-0'
		>
			<h1 className='text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl text-center text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold pb-6 pt-4'>
				Become a Volunteer
			</h1>
			<p className='text-base my-2 text-white text-center px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 pb-4 2xl:text-center'>
				Thanks for your interest in volunteering for Black Woman Can. Kindly
				fill the form below and we will reach out to you!
			</p>
			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='firstName' className='text-white text-base'>
					First Name <b className='text-red text-base'>*</b>
				</label>
				<input
					name='firstName'
					type='text'
					className='p-1 rounded text-sm'
					placeholder='Enter your First Name'
					onChange={formik.handleChange}
				/>
				{formik.touched.firstName && formik.errors.firstName ? (
					<div className='text-red text-base'>{formik.errors.firstName}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='lastName' className='text-white text-base'>
					Last Name <b className='text-red text-base'>*</b>
				</label>
				<input
					name='lastName'
					type='text'
					className='p-1 rounded text-sm'
					placeholder='Enter your Last Name'
					onChange={formik.handleChange}
				/>
				{formik.touched.lastName && formik.errors.lastName ? (
					<div className='text-red text-base'>{formik.errors.lastName}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='email' className='text-white text-base'>
						Email Address <b className='text-red text-base'>*</b>
					</label>
					<input
						name='email'
						type='email'
						className='w-full p-1 rounded text-sm'
						placeholder='Enter your Email Address'
						onChange={formik.handleChange}
					/>
					{formik.touched.email && formik.errors.email ? (
						<div className='text-red text-base'>{formik.errors.email}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='phoneNumber' className='text-white text-base'>
						Phone Number
					</label>
					<input
						name='phoneNumber'
						type='tel'
						className='w-full p-1 rounded bg-white text-sm'
						placeholder='Enter your Phone Number'
						onChange={formik.handleChange}
					/>
					{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
						<div className='text-red text-base'>
							{formik.errors.phoneNumber}
						</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='linkedInUrl' className='text-white text-base'>
					LinkedIn URL
				</label>
				<input
					name='linkedInUrl'
					type='url'
					className='w-full p-1 rounded text-sm'
					placeholder='Enter your LinkedIn URL'
					onChange={formik.handleChange}
				/>
				{formik.touched.linkedInUrl && formik.errors.linkedInUrl ? (
					<div className='text-red text-base'>{formik.errors.linkedInUrl}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<p className='text-white text-base'>
					Which of the following volunteer position do you want to apply for?
					<b className='text-red text-base'>*</b>
				</p>
				<select
					name='about'
					className='p-1 rounded text-sm'
					onChange={formik.handleChange}
				>
					<option>--Select--</option>
					<option value='content creator'>Content Creator</option>
					<option value='legal aid'>Legal Aid</option>
					<option value='legal practioner'>Legal Practioner</option>
					<option value='medical practitioner'>Medical Practitioner</option>
					<option value='live coach'>Live Coach</option>
					<option value='business educator'>Business Educator</option>
					<option value='counselor'>Counselor</option>
					<option value='graphic designer'>Graphic Designer</option>
					<option value='others'>Others</option>
				</select>
				{formik.touched.about && formik.errors.about ? (
					<div className='text-red text-base'>{formik.errors.about}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='firstName' className='text-white text-base'>
					If you have selected other, Kindly insert the volunteer position
					(Write N/A if you have selected a position above){' '}
					<b className='text-red text-base'>*</b>
				</label>
				<input
					name='firstName'
					type='text'
					className='p-1 rounded text-sm'
					placeholder='Enter your First Name'
					onChange={formik.handleChange}
				/>
				{formik.touched.firstName && formik.errors.firstName ? (
					<div className='text-red text-base'>{formik.errors.firstName}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='attachCv' className='text-white text-base'>
						Attach your CV <b className='text-red text-base'>*</b>
					</label>
					<input
						type='file'
						name='attachCv'
						className='block
							w-full
							px-3
							py-1
							text-sm
							text-black
							bg-white bg-clip-padding
							border border-solid border-white
							rounded'
						placeholder='Upload Your Cv'
						// onChange={(e) =>
						// 	formik.setFieldValue('attachCv', e.target.files[0])
						// }
						accept='.pdf'
						onChange={(event) => {
							if (event.currentTarget.files) {
								formik.setFieldValue('attachCv', event.currentTarget.files[0]);
							}
						}}
					/>
					{formik.touched.attachCv && formik.errors.attachCv ? (
						<div className='text-red text-base'>{formik.errors.attachCv}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='headShot' className='text-white text-base'>
						Headshot <b className='text-red text-base'>*</b>
					</label>
					<input
						type='file'
						name='headShot'
						className='block
							w-full
							px-3
							py-1
							text-sm
							text-black
							bg-white bg-clip-padding
							border border-solid border-white
							rounded'
						placeholder='Upload Your Image'
						// onChange={(e) =>
						// 	formik.setFieldValue('headShot', e.target.files[0])
						// }
						accept='image/*'
						onChange={(event) => {
							if (event.currentTarget.files) {
								formik.setFieldValue('headShot', event.currentTarget.files[0]);
							}
						}}
					/>
					{formik.touched.headShot && formik.errors.headShot ? (
						<div className='text-red text-base'>{formik.errors.headShot}</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='biography' className='text-white text-base'>
					Biography (Maximum of 50 words)
				</label>
				<textarea
					name='biography'
					type='textarea'
					className='p-1 rounded text-sm'
					rows='8'
					placeholder='Enter your Biography (Max 50 words)'
					onChange={formik.handleChange}
				></textarea>
				{formik.touched.biography && formik.errors.biography ? (
					<div className='text-red text-base'>{formik.errors.biography}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='checkbox' className='text-white text-base'>
					<input
						type='checkbox'
						name='checkbox'
						className='bg-white rounded mr-2 text-justify text-sm'
						onChange={formik.handleChange}
					/>
					I hereby voluntarily and without compensation authorize voice
					recording(s) to be made of me. I hereby give permission to BWC to use,
					reproduce, and distribute the video or voice recordings, including my
					name and submitted photo(s). I hereby agree to release Black Woman Can
					Inc from any claims, damages or liability arising out of such use or
					distribution. I understand that the use of my name, likeness, video
					and voice recordings will be primarily for the purposes of commercial
					use, education and/or promotion by this organization. I have read and
					understand the foregoing and I consent to the use of my picture, audio
					and/or video as specified for the above-described purpose(s). I
					further understand that no royalty, fee or other compensation of any
					character shall become payable to me. Consent - Media Release I agree
					to the above media/audio/video release Consent - Privacy I grant BWC
					permission to collect and store my data as per the BWC Privacy Policy.
					<p className='mt-6'>
						By checking this box, you agree to the audio/video release statement
						and
						<Link
							to='/terms-and-conditions'
							target='_blank'
							rel='noopener noreferrer'
							className='text-purple ml-2 text-justify'
						>
							Privacy Policy. <b className='text-red text-base'>*</b>
						</Link>
					</p>
				</label>
				{formik.touched.checkbox && formik.errors.checkbox ? (
					<div className='text-red text-base'>{formik.errors.checkbox}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 pb-4'>
				<div></div>
				<div className='flex justify-center'>
					<button
						type='submit'
						className='w-32 h-12 text-base bg-purple text-white rounded'
					>
						Submit
					</button>
				</div>
				<div></div>
			</div>
		</form>
	);
}

export default Volunteer;
