import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { Blog, BlogRecent, BlogPagination } from 'components/layout/Blog';

function BlogView() {
	return (
		<PageWrapper2>
			<div className='px-10 py-4 flex flex-col lg:flex-row xl:flex-row 2xl:flex-row'>
				<div className='lg:w-[70%] xl:w-[70%] 2xl:w-[70%] px-8'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
						Blog Posts
					</h1>
					<Blog />
					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mb-6'>
						<BlogPagination />
					</div>
				</div>
				<div className='lg:w-[30%] xl:w-[30%] 2xl:w-[30%]'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
						Recent Posts
					</h1>
					<BlogRecent />
				</div>
			</div>
		</PageWrapper2>
	);
}

export default BlogView;
