import React from 'react';
import PartnerWithUsIndividualView from './PartnerWithUsIndividualView';

export function PartnerWithUsIndividualContainer() {
	return (
		<div>
			<PartnerWithUsIndividualView />
		</div>
	);
}
