import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { WatchVideos } from 'components/layout/WatchVideos';
function WatchVideosView() {
	return (
		<PageWrapper2>
			<div className='grid grid-cols-1 mx-0 md:mx-20 lg:mx-40 xl:mx-60 2xl:mx-60 py-4 px-6'>
				<WatchVideos />
			</div>
		</PageWrapper2>
	);
}

export default WatchVideosView;
