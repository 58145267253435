import React from 'react';
import { logo, email, location } from 'assets/images';
import { instagram, facebook, linkedin, twitter } from 'assets/icons';
import { Link } from 'react-router-dom';
import { LinkButton } from 'components/widgets/Buttons';

function PageFooter() {
	return (
		<>
			<div className='text-white grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:mx-6'>
				<div className='rounded-full flex flex-row items-center'>
					<Link to='/'>
						<img
							src={logo}
							width={100}
							height={100}
							alt='Black Woman Can Logo'
						/>
					</Link>
				</div>
				<div>
					<h3 className='text-xl mt-5 mb-2 text-transparent font-semibold bg-clip-text bg-gradient-to-br from-purple to-gold'>
						Navigate
					</h3>
					<div className='grid gap-2 grid-cols-2 mt-6'>
						<div className='flex flex-col text-xs'>
							<LinkButton to='/'>Home</LinkButton>
							<LinkButton to='/about-us'>About Us</LinkButton>
							<LinkButton to='/announcement'>Announcement</LinkButton>
							<LinkButton to='/project'>BWC Project</LinkButton>
						</div>

						<div className='flex flex-col text-xs'>
							<LinkButton to='/blog'>Blog</LinkButton>
							<LinkButton to='/partners'>Our Partners</LinkButton>
							<LinkButton to='/program'>Programs</LinkButton>
							<LinkButton to='/'>Contact Us</LinkButton>
						</div>
					</div>
				</div>
				<div>
					<h3 className='text-xl mt-5 mb-2 text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold'>
						Contact Us
					</h3>
					<div className='text-white flex gap-x-2'>
						<a
							href='https://www.bing.com/maps?osid=ed4eafdb-3ed5-400f-93a4-8b72e79f1825&cp=33.054646~-96.699664&lvl=15&v=2&sV=2&form=S00027'
							rel='noreferrer'
							target='_blank'
							className='text-white flex gap-x-2 text-xs'
						>
							<img src={location} alt='Location Icon' width={20} /> 5040
							Frontier LN Plano TX 75023, USA
						</a>
					</div>
					<div className='text-white flex gap-x-2 pt-4 text-sm'>
						<a
							href='mailto:info@blackwomancan.com'
							rel='noreferrer'
							target='_blank'
							className='text-white flex gap-x-2 text-xs'
						>
							<img src={email} alt='Email Icon' width={20} />{' '}
							info@blackwomancan.com
						</a>
					</div>
					<h3 className='font-bold text-lg mt-5 mb-2'>Get In Touch With Us</h3>
					<div className='flex gap-x-6'>
						<a
							href='https://instagram.com/black_womancan'
							rel='noreferrer'
							target='_blank'
						>
							<img src={instagram} alt='Instagram Icon' width={25} />
						</a>
						<a
							href='https://www.linkedin.com/company/black-woman-can/'
							rel='noreferrer'
							target='_blank'
						>
							<img src={linkedin} alt='LinkedIn Icon' width={25} />
						</a>
						<a
							href='https://m.facebook.com/blackwomancan/'
							rel='noreferrer'
							target='_blank'
						>
							<img src={facebook} alt='Facebook Icon' width={25} />
						</a>
						<a
							href='https://mobile.twitter.com/blackwomancan'
							rel='noreferrer'
							target='_blank'
						>
							<img src={twitter} alt='Twitter Icon' width={25} />
						</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default PageFooter;
