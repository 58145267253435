import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { ProgramSection } from 'components/layout/ProgramSection';

function ProgramsView() {
	return (
		<PageWrapper2>
			<div className='px-20 py-4'>
				<div className='lg:w-[80%] xl:w-[80%] 2xl:w-[80%]'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
						Our Past Events
					</h1>
					<ProgramSection />
				</div>
				<div className='lg:w-[20%] xl:w-[20%] 2xl:w-[20%]'></div>
			</div>
		</PageWrapper2>
	);
}

export default ProgramsView;
