export const HOME = '/';
export const ABOUT_US = '/about-us';
export const SHARE_EXPERIENCE = '/share-experience';
export const MEDIA = '/media';
export const GET_INVOLVED = '/get-involved';
export const BECOME_A_MEMBER = '/become-a-member';
export const ANNOUNCEMENT = '/announcement';
export const PROGRAM = '/program';
export const PROJECT = '/project';
export const BLOG = '/blog';
export const UPLOAD_A_VIDEO = '/upload-a-video';
export const WRITE_AN_ARTICLE = '/write-an-article';
export const WATCH_VIDEOS = '/watch-videos';
export const READ_ARTICLES = '/read-articles';
export const DONATE = '/donate';
export const PARTNER_WITH_US_COMPANY = '/partner-with-us-company';
export const PARTNER_WITH_US_INDIVIDUAL = '/partner-with-us-individual';
export const GUEST_SPEAKER = '/guest-speaker';
export const VOLUNTEER = '/volunteer';
export const SHOW_HOST = '/show-host';
export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const PROGRAM_ARTICLE = '/program-article/:title';
export const TEAM = '/team';
export const PARTNERS = '/partners';
export const BLOG_ARTICLE = '/blog-article/:title';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const SHARE_EXPERIENCE_SIGN_UP = '/share-experience-sign-up';
export const SHARE_EXPERIENCE_SIGN_IN = '/share-experience-sign-in';
