import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function UploadVideoForm() {
	const formik = useFormik({
		initialValues: {
			fullName: '',
			email: '',
			topic: '',
			video: '',
		},
		validationSchema: Yup.object({
			fullName: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			email: Yup.string().email('Invalid email address').required('Required'),
			topic: Yup.string()
				.min(3, 'Must be 3 characters or more')
				.required('Required'),
			video: Yup.mixed().required('Required'),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/api/auth/register',
				data: formik.values,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					console.log(response);
				})
				.catch(function (error) {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		},
	});
	return (
		<>
			<h1 className='text-3xl text-center text-transparent font-semibold bg-clip-text bg-gradient-to-b from-purple to-gold pb-6 pt-4'>
				Share Your Story
			</h1>
			<form
				onSubmit={formik.handleSubmit}
				className='sm:py-8 md:py-8 lg:px-8 lg:py-8 xl:px-8 xl:py-8 2xl:px-8 2xl:py-8 mb-10 border-[#C52EC8] border-solid border-2'
			>
				<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 sm:px-4 md:px-10 lg:px-10 xl:px-10 2xl:px-10 m-4'>
					<label htmlFor='fullName' className='text-black text-base'>
						Full Name <b className='text-red text-base'>*</b>
					</label>
					<input
						name='fullName'
						type='text'
						className='p-1 rounded border-[#C52EC8] border-solid border-2 text-sm'
						placeholder='Enter your Full Name'
						onChange={formik.handleChange}
					/>
					{formik.touched.fullName && formik.errors.fullName ? (
						<div className='text-red text-base'>{formik.errors.fullName}</div>
					) : null}
				</div>

				<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 sm:px-4 md:px-10 lg:px-10 xl:px-10 2xl:px-10 m-4'>
					<label htmlFor='email' className='text-black text-base'>
						Email Address <b className='text-red text-base'>*</b>
					</label>
					<input
						name='email'
						type='email'
						className='p-1 rounded border-[#C52EC8] border-solid border-2 text-sm'
						placeholder='Enter your Email Address'
						onChange={formik.handleChange}
					/>
					{formik.touched.email && formik.errors.email ? (
						<div className='text-red text-base'>{formik.errors.email}</div>
					) : null}
				</div>

				<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 sm:px-4 md:px-10 lg:px-10 xl:px-10 2xl:px-10 m-4'>
					<label htmlFor='topic' className='text-black text-base'>
						Topic <b className='text-red text-base'>*</b>
					</label>
					<input
						name='topic'
						type='text'
						className='p-1 rounded border-[#C52EC8] border-solid border-2 text-sm'
						placeholder='Enter your Topic Title'
						onChange={formik.handleChange}
					/>
					{formik.touched.topic && formik.errors.topic ? (
						<div className='text-red text-base'>{formik.errors.topic}</div>
					) : null}
				</div>

				<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 sm:px-4 md:px-10 lg:px-10 xl:px-10 2xl:px-10 m-4'>
					<label htmlFor='video' className='text-black text-base'>
						Upload a Video (Maximum 50MB){' '}
						<b className='text-red text-base'>*</b>
					</label>
					<input
						type='file'
						name='video'
						className='block
							w-full
							px-3
							py-1
							text-sm
							text-black
							bg-white bg-clip-padding
							border-2 border-solid border-[#C52EC8]
							rounded'
						placeholder='Upload Your Video'
						accept='video/*'
						onChange={formik.handleChange}
					/>
					{formik.touched.video && formik.errors.video ? (
						<div className='text-red text-base'>{formik.errors.video}</div>
					) : null}
				</div>

				<div className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 mb-4'>
					<div></div>
					<div className='flex justify-center'>
						<button
							type='submit'
							className='w-32 h-12 text-base bg-purple text-white rounded'
						>
							Submit Video
						</button>
					</div>
					<div></div>
				</div>
			</form>
		</>
	);
}

export default UploadVideoForm;
