import React from 'react';
import SignInView from './SignInView';

export function SignInContainer() {
	return (
		<div>
			<SignInView />
		</div>
	);
}
