import React from 'react';
import PropTypes from 'prop-types';
import { PageNavBar } from 'components/modules/navBars';
import { PageFooter } from 'components/modules/footers';
import { Copyright } from 'components/modules/copyright';

function PageWrapper2({ children }) {
	return (
		<div>
			<nav className='bg-white z-50'>
				<div>
					<PageNavBar />
				</div>
			</nav>

			<main>
				<div className='min-h-[75vh] box-border w-full'>{children}</div>
			</main>

			<footer className='flex justify-center items-center mt-4 bg-[#1F1F1F]'>
				<div className='md:container py-[40px] px-4'>
					<PageFooter />
				</div>
			</footer>

			<div className='bg-darkgrey flex justify-center items-center'>
				<div className='md:container py-4 px-4 lg:px-16 '>
					<Copyright />
				</div>
			</div>
		</div>
	);
}

PageWrapper2.propTypes = {
	children: PropTypes.any,
};

export default PageWrapper2;
