import { blog1, circle, blog2 } from 'assets/images';

const blog = [
	{
		id: 1,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner1',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 2,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner2',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes.....',
		writerName: 'Jessica Watkins',
		WriterTitle: 'Content Writer',
		writerImage: circle,
		views: '1.4k',
	},
	{
		id: 3,
		image: blog2,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner3',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes.....',
		writerName: 'Jessica Watkins',
		WriterTitle: 'Content Writer',
		writerImage: circle,
		views: '1.4k',
	},
	{
		id: 4,
		image: blog2,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner4',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 5,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner5',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 6,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner6',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 7,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner7',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 8,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner8',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 9,
		image: blog1,
		date: 'April 28,2022',
		title: 'Keechant Sewell: Future first Woman to serve as NYPD Commissioner9',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 10,
		image: blog1,
		date: 'April 28,2022',
		title:
			'Keechant Sewell: Future first Woman to serve as NYPD Commissioner10',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 11,
		image: blog1,
		date: 'April 28,2022',
		title:
			'Keechant Sewell: Future first Woman to serve as NYPD Commissioner11',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
	{
		id: 12,
		image: blog1,
		date: 'April 28,2022',
		title:
			'Keechant Sewell: Future first Woman to serve as NYPD Commissioner12',
		body: 'Black Woman Can is an international movement to uplift Black Women around the world through showcasing the achievements of Black Women throughout history, capturing their daily struggles for Justice and Equality, debunking myths and challenging stereotypes',
		writerImage: circle,
		writerName: 'Nancy',
		WriterTitle: 'Content Writer',
		views: '1.4k',
	},
];

export default blog;
