import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { linkedin, google } from 'assets/icons';
import { success } from 'assets/images';
import Swal from 'sweetalert2';

function ShareExperienceSignIn() {
	const strongPasswordRegex =
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
			password: Yup.string()
				.min(8)
				.required('Required')
				.matches(strongPasswordRegex),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/api/auth/signin',
				data: formik.values,
				headers: { 'Content-Type': 'application/json' },
			})
				.then(function () {
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submitted Successfully',
						text: 'You have successfully Signed In.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
				})
				.catch(function () {
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submission was Unsuccessful',
						text: 'User with Email Not Found. Kindly try again.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
				});
		},
	});
	return (
		<form
			onSubmit={formik.handleSubmit}
			className='bg-[#0E1022] pl-8 pr-16 h-full w-full pb-10 sm:py-20 md:py-20 lg:px-20 lg:py-20 xl:px-20 xl:py-20 2xl:px-20 2xl:py-20'
		>
			<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold py-8 text-2xl text-center'>
				Kindly Sign In to Tell your Story
			</h1>
			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1'>
				<p className='text-white text-base text-center mt-6'>
					Don’t have an account?{' '}
					<Link to='/share-experience-sign-up' className='text-purple mr-2'>
						Sign Up
					</Link>
				</p>
			</div>
			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-20 mb-4'>
				<label htmlFor='email' className='text-white text-base'>
					Email Address <b className='text-red text-base'>*</b>
				</label>
				<input
					name='email'
					type='email'
					className='p-1 rounded text-sm'
					placeholder='Enter your Email Address'
					onChange={formik.handleChange}
				/>
				{formik.touched.email && formik.errors.email ? (
					<div className='text-red text-base'>{formik.errors.email}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-20 mb-4'>
				<label htmlFor='password' className='text-white text-base'>
					Password <b className='text-red text-base'>*</b>
				</label>
				<input
					name='password'
					type='password'
					className='w-full p-1 rounded text-sm'
					placeholder='********'
					onChange={formik.handleChange}
				/>
				{formik.touched.email && formik.errors.email ? (
					<div className='text-red text-base'>{formik.errors.email}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-20 mb-4'>
				<label htmlFor='check' className='text-white text-base'>
					<input
						type='checkbox'
						name='check'
						className='bg-white rounded mr-2 text-sm'
					/>
					Remember me
				</label>
			</div>

			<div className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'>
				<div></div>
				<div className='flex justify-center'>
					<button
						type='submit'
						className='w-32 h-12 text-base bg-purple text-white rounded'
					>
						Sign In
					</button>
				</div>
				<div></div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-20 mb-8 mt-10'>
				<div className='text-center flex pt-4 justify-between items-center pb-4'>
					<div className='border-b border-lilac w-[80%] text-lilac'></div>{' '}
					<Link to='#' className='px-2 text-center text-white w-[20%] text-sm'>
						Or continue with
					</Link>
					<div className='border-b border-lilac w-[80%] text-lilac'></div>
				</div>

				<div className='flex justify-center gap-x-10 mt-4'>
					<a href='#' rel='noreferrer' target='_blank' className='text-white'>
						<img src={google} width={40} />
					</a>
					<a href='#' rel='noreferrer' target='_blank' className='text-white'>
						<img src={linkedin} width={40} />
					</a>
				</div>
			</div>
		</form>
	);
}

export default ShareExperienceSignIn;
