import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { ProgramSingle } from 'components/layout/ProgramSection';

function ProgramArticlesView() {
	return (
		<PageWrapper2>
			<div className='px-10 md:px-10 lg:px-20 xl:px-20 2xl:px-20 py-4'>
				<div>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
						Our Past Events
					</h1>
					<ProgramSingle />
				</div>
			</div>
		</PageWrapper2>
	);
}

export default ProgramArticlesView;
