import React from 'react';
import UploadAVideoView from './UploadAVideoView';

export function UploadAVideoContainer() {
	return (
		<div>
			<UploadAVideoView />
		</div>
	);
}
