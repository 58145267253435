import React from 'react';
import BlogView from './BlogView';

export function BlogContainer() {
	return (
		<div>
			<BlogView />
		</div>
	);
}
