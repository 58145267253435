import React from 'react';
import TermsandConditionsView from './TermsandConditionsView';

export function TermsandConditionsContainer() {
	return (
		<div>
			<TermsandConditionsView />
		</div>
	);
}
