import { React } from 'react';
import { PartnerData } from 'Data';

//This is the Partners section under the Partners page
const Partners = () => {
	return (
		<>
			{PartnerData.map((partner, id) => (
				<div key={id}>
					<div>
						<img
							src={partner.image}
							alt={partner.image}
							className='w-60% mx-auto mt-6'
						/>
					</div>
				</div>
			))}
		</>
	);
};

export default Partners;
