import React from 'react';
import {
	WelcomeSection,
	AboutUsSection,
	SliderComponent,
	OurTeamSection,
	GetInTouchSection,
} from 'components/layout/Home';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { Link } from 'react-router-dom';
function HomePageView() {
	return (
		<>
			<PageWrapper2>
				<div className='w-full'>
					<WelcomeSection />
					<div className='mt-[-80px] md:mt-[-150px] lg:mt-[-220px] xl:mt-[-250px] 2xl:mt-[-250px]'>
						<SliderComponent />
					</div>
				</div>
				<div>
					<div className='w-full pb-20 pt-20 px-14 border-b-2 border-solid border-[#f2f5f7]'>
						<AboutUsSection />
					</div>
				</div>
				<div className='w-full my-20 px-14'>
					<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'>
						<div></div>
						<div>
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-100 text-3xl text-center'>
								Meet Our Team
							</h1>
						</div>
						<div></div>
					</div>
					<div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-10'>
						<OurTeamSection />
					</div>
					<div className='grid sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 pt-10'>
						<div></div>
						<div className='flex justify-center'>
							<button className='w-32 h-12 text-base bg-purple rounded text-white'>
								<Link to='/team'>View All</Link>
							</button>
						</div>
						<div></div>
					</div>
				</div>
				<div className='w-full'>
					<GetInTouchSection />
				</div>
			</PageWrapper2>
		</>
	);
}

export default HomePageView;
