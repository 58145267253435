const words = [
	{
		id: 1,
		date: 'April 28,2022',
		title: 'Keechant Sewell',
		comment:
			'This ia a really inspiring article. I am really excited about the articles on this website',
	},
	{
		id: 2,
		date: 'April 28,2022',
		title: 'Keechant Sewell',
		comment:
			'This ia a really inspiring article. I am really excited about the articles on this website',
	},
	{
		id: 3,
		date: 'April 28,2022',
		title: 'Keechant Sewell',
		comment:
			'This is a very nice and educative video. Thank you Black Woman Can',
	},
	{
		id: 4,
		date: 'April 28,2022',
		title: 'Keechant Sewell',
		comment:
			'This is a very nice and educative video. Thank you Black Woman Can',
	},
	{
		id: 5,
		date: 'April 28,2022',
		title: 'Keechant Sewell',
		comment:
			'This is a very nice and educative video. Thank you Black Woman Can',
	},
];

export default words;
