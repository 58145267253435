import React from 'react';
import BecomeAMemberView from './BecomeAMemberView';

export function BecomeAMemberContainer() {
	return (
		<div>
			<BecomeAMemberView />
		</div>
	);
}
