import { React } from 'react';
import { Link } from 'react-router-dom';
import { BlogData } from 'Data';

//This is the ongoing event section under the announcement page
const Blog = () => {
	const currentPage = 2;
	const postsPerPage = 6;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = BlogData.slice(indexOfFirstPost, indexOfLastPost);

	return (
		<>
			{currentPosts.map((blog, id) => (
				<div key={id} className='flex pt-8'>
					<div>
						<img src={blog.image} alt={blog.title} className='w-full' />
					</div>
					<div className='px-4'>
						<h2 className='font-bold text-[#000000] text-sm'>{blog.title}</h2>
						<p className='text-[#000000] text-sm'>
							{blog.body.substring(0, 60)}...
							<Link
								to={`/blog-article/${blog.title}`}
								className='text-[#952B95] font-bold'
							>
								Read more
							</Link>
						</p>
						<h6 className='font-semibold text-[#7A7B74] text-sm text-right'>
							{blog.date}
						</h6>
					</div>
				</div>
			))}
		</>
	);
};

export default Blog;
