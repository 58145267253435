import { React } from 'react';
import { Link } from 'react-router-dom';
import { BlogData } from 'Data';

//This is the ongoing event section under the announcement page
const BlogPagination = () => {
	const currentPage = 2;
	const postsPerPage = 2;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = BlogData.slice(indexOfFirstPost, indexOfLastPost);

	return (
		<>
			{currentPosts.map((blog, id) => (
				<div
					key={id}
					className='flex flex-col pt-8 gap-x-4 justify-center items-center'
				>
					<div>
						<img src={blog.image} alt={blog.title} className='w-full' />
					</div>
					<div className='pt-4'>
						<h6 className='font-semibold text-[#7A7B74] text-sm'>
							{blog.date}
						</h6>
						<p className='font-bold text-lg pt-2'>{blog.writerName}</p>
						<h2 className='font-semibold text-[#000000] text-base pt-4'>
							{blog.title}
						</h2>
						<p className='pt-4 text-sm text-justify'>
							{blog.body.substring(0, 260)}
						</p>
						<div className='flex flex-row pt-4 justify-between items-center'>
							<div>
								<button className='w-32 h-10 text-base bg-purple rounded text-white mt-4'>
									<Link to={`/blog-article/${blog.title}`}>Read more</Link>
								</button>
							</div>
							<div>
								<p className='font-bold text-xs'>{blog.views} Reads</p>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default BlogPagination;
