import { useState, React } from 'react';
// import { circle } from 'assets/images';
import { ThumbButton } from 'components/widgets/Buttons';
//This is the comment section under the program page
const LikeButton = () => {
	//  Counter is a state initialized to 0
	const [counter, setCounter] = useState(0);

	// Function is called everytime increment button is clicked
	const handleClick1 = () => {
		// Counter state is incremented
		setCounter(counter + 1);
	};

	return (
		<>
			<div>
				{/* The button and likes section */}
				<div className='flex flex-row mt-2'>
					<div>
						<button
							className='flex flex-row justify-center items-center w-20 h-10 text-base bg-purple rounded text-white mt-4'
							onClick={handleClick1}
						>
							<ThumbButton />
							Button
						</button>
					</div>
					<div>
						<p className='text-base mt-6 ml-4 font-medium'>{counter} Likes</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default LikeButton;
