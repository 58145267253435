import { React, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ArrowButton } from 'components/widgets/Buttons';
import axios from 'axios';

function PartnerWithUsCompany() {
	const [open, setOpen] = useState(false);
	const menuRef = useRef();
	const buttonRef = useRef();

	window.addEventListener('click', (e) => {
		if (e.target !== menuRef.current && e.target !== buttonRef.current) {
			setOpen(false);
		}
	});

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			business: '',
			phoneNumber: '',
			about: '',
			interested: '',
			checkbox: false,
		},
		validationSchema: Yup.object({
			about: Yup.string().oneOf(
				['LinkedIn', 'Social media', 'Friends', 'Referral', 'Others'],
				'Invalid Answer'
			),
			name: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			business: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			email: Yup.string().email('Invalid email address').required('Required'),
			phoneNumber: Yup.string().min(10).matches(phoneRegExp),
			interested: Yup.string().oneOf(
				['BWC write a Book Project', 'BWC Show', 'BWC Mentorship Program'],
				'Invalid Answer'
			),
			checkbox: Yup.bool()
				.required('Required')
				.oneOf([true], 'Accept the terms and conditions'),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/api/auth/register',
				data: formik.values,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					console.log(response);
				})
				.catch(function (error) {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					console.log(error.config);
				});
		},
	});
	return (
		<form
			onSubmit={formik.handleSubmit}
			className='bg-[#0E1022] py-8 md:py-8 lg:px-8 lg:py-8 xl:px-8 xl:py-8 2xl:px-8 2xl:py-8 mb-0'
		>
			<h1 className='text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl text-center text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold pb-6 pt-4'>
				Partner with us as a Company
			</h1>
			<p className='text-base my-2 text-white text-center px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 pb-4 2xl:text-center'>
				Thank you for your interest in partnering with us. Please fill out this
				form and we will reach out to you for more details.
			</p>
			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='partner' className='text-white text-base'>
					Partner with us as
				</label>
				<div className='text-black bg-white w-full p-1 rounded'>
					<div className='flex justify-between'>
						<button
							ref={buttonRef}
							onClick={() => setOpen(!open)}
							className='text-sm'
						>
							--Select--
						</button>
						<ArrowButton />
					</div>
					{open && (
						<div ref={menuRef} className='mt-2'>
							<ul>
								<li onClick={() => setOpen(false)}>
									<Link to='/partner-with-us-individual' className='text-sm'>
										Individual
									</Link>
								</li>
								<li onClick={() => setOpen(false)}>
									{' '}
									<Link to='/partner-with-us-company' className='text-sm'>
										Company
									</Link>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='name' className='text-white text-base'>
						Company Name <b className='text-red text-base'>*</b>
					</label>
					<input
						name='name'
						type='text'
						className='w-full p-1 rounded text-sm'
						placeholder='Enter your Company Name'
						onChange={formik.handleChange}
					/>
					{formik.touched.name && formik.errors.name ? (
						<div className='text-red text-base'>{formik.errors.name}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='business' className='text-white text-base'>
						Type of Business <b className='text-red text-base'>*</b>
					</label>
					<input
						name='business'
						type='text'
						className='w-full p-1 rounded text-sm'
						placeholder='Enter your Type of Business'
						onChange={formik.handleChange}
					/>
					{formik.touched.business && formik.errors.business ? (
						<div className='text-red text-base'>{formik.errors.business}</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='grid grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='email' className='text-white text-base'>
						Company Address <b className='text-red text-base'>*</b>
					</label>
					<input
						name='email'
						type='email'
						className='p-1 rounded text-sm'
						placeholder='Enter your Company Address'
						onChange={formik.handleChange}
					/>
					{formik.touched.email && formik.errors.email ? (
						<div className='text-red text-base'>{formik.errors.email}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='phoneNumber' className='text-white text-base'>
						Phone Number
					</label>
					<input
						name='phoneNumber'
						type='tel'
						className='w-full p-1 rounded bg-white text-sm'
						placeholder='Enter your Phone Number'
						onChange={formik.handleChange}
					/>
					{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
						<div className='text-red text-base'>
							{formik.errors.phoneNumber}
						</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='about' className='text-white text-base'>
					How did you hear about us?
				</label>
				<select
					name='about'
					className='p-1 rounded text-sm'
					onChange={formik.handleChange}
				>
					<option>--Select--</option>
					<option value='LinkedIn'>LinkedIn</option>
					<option value='Social media'>Social media</option>
					<option value='Friends'>Friends</option>
					<option value='Referral'>Referral</option>
					<option value='Others'>Others</option>
				</select>
				{formik.touched.about && formik.errors.about ? (
					<div className='text-red text-base'>{formik.errors.about}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='interested' className='text-white text-base'>
					Which of these initiatives are you interested in?
				</label>
				<select
					name='interested'
					className='p-1 rounded text-sm'
					onChange={formik.handleChange}
				>
					<option>--Select--</option>
					<option value='BWC write a Book Project'>
						BWC write a Book Project
					</option>
					<option value='BWC Show'>BWC Show</option>
					<option value='BWC Mentorship Program'>BWC Mentorship Program</option>
				</select>
				{formik.touched.interested && formik.errors.interested ? (
					<div className='text-red text-base'>{formik.errors.interested}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='checkbox' className='text-white text-base'>
					<input
						type='checkbox'
						name='checkbox'
						className='bg-white rounded mr-2 text-sm'
						onChange={formik.handleChange}
					/>
					By ticking this box, you agree to the{' '}
					<Link
						to='/terms-and-conditions'
						target='_blank'
						rel='noopener noreferrer'
						className='text-purple mr-2'
					>
						terms and conditions
					</Link>
					of our organization <b className='text-red text-base'>*</b>
				</label>
				{formik.touched.checkbox && formik.errors.checkbox ? (
					<div className='text-red text-base'>{formik.errors.checkbox}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 pb-4'>
				<div></div>
				<div className='flex justify-center'>
					<button
						type='submit'
						className='w-32 h-12 text-base bg-purple text-white rounded'
					>
						Submit
					</button>
				</div>
				<div></div>
			</div>
		</form>
	);
}

export default PartnerWithUsCompany;
