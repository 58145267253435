import { project, project1 } from 'assets/images';

const Project = [
	{
		id: 1,
		image: project,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 2,
		image: project,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 3,
		image: project1,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 4,
		image: project1,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 5,
		image: project,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 6,
		image: project,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 7,
		image: project1,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
	{
		id: 8,
		image: project1,
		title:
			'ENLIGHTENING THE GIRL-CHILD: A VISIT TO ABUJA GIRLS SECONDARY SCHOOL',
		body: 'The only thing constant in life is change, whether in appearances, relationships, or career choices. This is because we live in a dynamic world, and as it evolves, we need to grow with it by consciously and consistently learning, unlearning, and relearning new concepts each day.',
	},
];

export default Project;
