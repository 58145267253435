import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { links } from './Mylinks';
import { ArrowButton } from 'components/widgets/Buttons';

const NavBarLinks = () => {
	const [heading, setHeading] = useState('');
	return (
		<>
			{links.map((link, id) => (
				<div key={id}>
					<div className='px-3 text-left lg:cursor-pointer group'>
						<h1
							className='py-4 flex justify-between items-center lg:pr-0 pr-5 group text-sm font-semibold'
							onClick={() => {
								heading !== link.name ? setHeading(link.name) : setHeading('');
							}}
						>
							{link.name}
							<span className='text-xl lg:hidden inline'>
								<ArrowButton
									name={`${
										heading === link.name ? 'chevron-up' : 'chevron-down'
									}`}
								></ArrowButton>
							</span>
							<span className='text-xl lg:mt-1 lg:ml-2  lg:block hidden group-hover:rotate-180 group-hover:-mt-2'>
								<ArrowButton name='chevron-down'></ArrowButton>
							</span>
						</h1>
						{link.submenu && (
							<div>
								<div className='absolute top-10 hidden group-hover:lg:block hover:lg:block'>
									<div className='py-3'>
										<div
											className='w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45'
										></div>
									</div>
									<div className='bg-white p-5 grid grid-cols-1 gap-10'>
										{link.sublinks.map((mysublinks, id) => (
											<div key={id}>
												<h1 className='text-lg font-semibold'>
													{mysublinks.Head}
												</h1>
												{mysublinks.sublink.map((slink, id) => (
													<li key={id} className='text-sm text-gray-600 my-2.5'>
														<Link
															to={slink.link}
															className='hover:text-primary'
														>
															{slink.name}
														</Link>
													</li>
												))}
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
					{/* Mobile menus */}
					<div
						className={`
            ${heading === link.name ? 'lg:hidden' : 'hidden'}
          `}
					>
						{/* sublinks */}
						{link.sublinks.map((slinks, id) => (
							<div key={id}>
								<div>
									{/* <h1
										onClick={() =>
											subHeading !== slinks.Head
												? setSubHeading(slinks.Head)
												: setSubHeading('')
										}
										className='py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center'
									>
										{slinks.Head}

										<span className='text-xl md:mt-1 md:ml-2 inline'>
											<ArrowButton
												name={`${
													subHeading === slinks.Head
														? 'chevron-up'
														: 'chevron-down'
												}`}
											></ArrowButton>
										</span>
									</h1> */}
									<div
										className={`${
											heading === link.name ? 'lg:hidden' : 'hidden'
										}`}
									>
										{slinks.sublink.map((slink, id) => (
											<li key={id} className='py-3 pl-2 text-sm'>
												<Link to={slink.link}>{slink.name}</Link>
											</li>
										))}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			))}
		</>
	);
};

export default NavBarLinks;
