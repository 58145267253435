import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { cancel, logo, menu } from 'assets/images';
import NavBarLinks from './NavBarLinks';

const NavBar = () => {
	const [open, setOpen] = useState(false);
	return (
		<nav>
			<div className='flex items-center font-medium justify-around px-0 md:px-6 lg:px-0 xl:px-0 2xl:px-0'>
				<div className='p-5 lg:w-auto w-full flex flex-row'>
					<div className='w-[100%] flex flex-row gap-x-4 md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center'>
						<img src={logo} alt='Black Woman Can Logo' width={35} height={35} />
						<p className='text-base text-transparent font-[600] bg-clip-text bg-gradient-to-r from-purple to-gold'>
							Black Woman Can
						</p>
					</div>
					<div
						className='text-3xl lg:hidden w-[20%] flex justify-end'
						onClick={() => setOpen(!open)}
					>
						<img src={menu} alt='logo' name={`${open ? 'close' : 'menu'}`} />
					</div>
				</div>
				<ul className='lg:flex hidden items-center gap-4'>
					<li className='rounded-lg hover:text-purple'>
						<h1 className='py-2 px-4 inline-block text-sm font-semibold'>
							<Link to='/'>Home</Link>
						</h1>
					</li>
					<NavBarLinks />
					<button className='w-24 h-10 text-base border-solid border-2 border-purple rounded text-purple'>
						<Link to='/sign-in'>Sign In</Link>
					</button>
					<button className='w-24 h-10 text-base bg-purple rounded text-white'>
						<Link to='/sign-up'>Sign Up</Link>
					</button>
				</ul>
				<div className='lg:block hidden'>{/* <Button /> */}</div>
				{/* Mobile nav */}
				<ul
					className={`
        lg:hidden bg-white text-black fixed w-full top-0 overflow-y-auto bottom-0 pb-24 pl-4 z-50
        duration-500 ${open ? 'left-0' : 'left-[-100%]'}
        `}
				>
					{' '}
					<div className='p-4 lg:w-auto w-full flex flex-row'>
						<div className='w-[80%] flex flex-row gap-x-4 md:gap-x-4 md:flex-row lg:flex-col xl:flex-col 2xl:flex-col items-center'>
							<img
								src={logo}
								alt='Black Woman Can Logo'
								width={35}
								height={35}
							/>
							<p className='lg:text-center text-base text-transparent font-[600] bg-clip-text bg-gradient-to-r from-purple to-gold'>
								Black Woman Can
							</p>
						</div>
						<div
							className='text-3xl lg:hidden w-[20%] flex justify-end'
							onClick={() => setOpen(!open)}
						>
							<img
								src={cancel}
								alt='logo'
								name={`${open ? 'close' : 'menu'}`}
							/>
						</div>
					</div>
					<li>
						<Link to='/' className='px-3 inline-block text-base'>
							Home
						</Link>
					</li>
					<NavBarLinks />
					<div className='flex flex-col gap-y-4 mt-6'>
						<button className='w-24 h-10 text-base border-solid border-2 border-purple rounded text-purple'>
							<Link to='/sign-in'>Sign In</Link>
						</button>
						<button className='w-24 h-10 text-base bg-purple rounded text-white'>
							<Link to='/sign-up'>Sign Up</Link>
						</button>
					</div>
				</ul>
			</div>
		</nav>
	);
};

export default NavBar;
