import { React } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { SlidesData } from 'Data';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper';

function SliderComponent() {
	return (
		<>
			<Swiper
				slidesPerView={4}
				spaceBetween={20}
				slidesPerGroup={7}
				loop={true}
				loopFillGroupWithBlank={true}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				navigation={true}
				modules={[Pagination, Navigation, Autoplay]}
				className='mySwiper'
				style={{
					'--swiper-navigation-color': 'white',
				}}
			>
				{SlidesData.map((slide, id) => (
					<div key={id}>
						<SwiperSlide>
							<img src={slide.image} alt={slide.image} className='mx-auto' />
						</SwiperSlide>
					</div>
				))}
			</Swiper>
		</>
	);
}

export default SliderComponent;
