import React from 'react';
import { bwcMission } from 'assets/images';
import { Link } from 'react-router-dom';

function AboutUsSection() {
	return (
		<>
			<div className='flex flex-col-reverse md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-y-6'>
				<div className='flex flex-col justify-center'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-80 text-2xl'>
						Our Mission
					</h1>
					<p className='text-sm my-2 text-justify'>
						To showcase and celebrate the achievements of Black Women throughout
						History.
					</p>
					<button className='w-32 h-10 text-base bg-purple rounded text-white mt-4'>
						<Link to='/about-us'>Learn More</Link>
					</button>
				</div>
				<div className='flex flex-col justify-center'>
					<img src={bwcMission} className='w-80% mx-auto' alt='Mission Image' />
				</div>
			</div>
		</>
	);
}

export default AboutUsSection;
