import {
	BECOME_A_MEMBER,
	ANNOUNCEMENT,
	PROGRAM,
	PROJECT,
	BLOG,
	UPLOAD_A_VIDEO,
	WRITE_AN_ARTICLE,
	WATCH_VIDEOS,
	READ_ARTICLES,
	DONATE,
	PARTNER_WITH_US_COMPANY,
	GUEST_SPEAKER,
	VOLUNTEER,
	SHOW_HOST,
	ABOUT_US,
	PARTNERS,
	TEAM,
} from 'navigation/constants';
export const links = [
	{
		name: 'About Us',
		submenu: true,
		sublinks: [
			{
				// Head: 'About Us',
				sublink: [
					{ nav: 1, name: 'Mission', link: ABOUT_US },
					{ nav: 2, name: 'Vision', link: ABOUT_US },
					{ nav: 3, name: 'Objectives', link: ABOUT_US },
					{ nav: 4, name: 'Meet Our Team', link: TEAM },
					{ nav: 5, name: 'Our Partners', link: PARTNERS },
				],
			},
		],
	},
	{
		name: 'Media',
		submenu: true,
		sublinks: [
			{
				sublink: [
					{ nav: 6, name: 'Announcement', link: ANNOUNCEMENT },
					{ nav: 7, name: 'Program', link: PROGRAM },
					{ nav: 8, name: 'BWC Project', link: PROJECT },
					{ nav: 9, name: 'Blog', link: BLOG },
				],
			},
		],
	},
	{
		name: 'Share Experience',
		submenu: true,
		sublinks: [
			{
				sublink: [
					{ nav: 10, name: 'Upload A Video', link: UPLOAD_A_VIDEO },
					{ nav: 12, name: 'Watch Videos', link: WATCH_VIDEOS },
					{ nav: 11, name: 'Write An Article', link: WRITE_AN_ARTICLE },
					{ nav: 13, name: 'Read Articles', link: READ_ARTICLES },
				],
			},
		],
	},
	{
		name: 'Get Involved',
		submenu: true,
		sublinks: [
			{
				sublink: [
					{ nav: 14, name: 'Become A Member', link: BECOME_A_MEMBER },
					{ nav: 15, name: 'Donate', link: DONATE },
					{ nav: 16, name: 'Partner With Us', link: PARTNER_WITH_US_COMPANY },
					{ nav: 17, name: 'Guest Speaker', link: GUEST_SPEAKER },
					{ nav: 18, name: 'Show Host', link: SHOW_HOST },
					{ nav: 19, name: 'Volunteer', link: VOLUNTEER },
				],
			},
		],
	},
];
