import React from 'react';
import { ProtectedRoutes } from 'components/guards';
import {
	Home,
	AboutUs,
	Announcement,
	Program,
	Project,
	Blog,
	BecomeAMember,
	UploadAVideo,
	WriteAnArticle,
	WatchVideos,
	ReadArticles,
	Donate,
	PartnerWithUsCompany,
	PartnerWithUsIndividual,
	GuestSpeaker,
	ShowHost,
	Volunteer,
	SignIn,
	SignUp,
	ProgramArticle,
	Team,
	Partners,
	BlogArticle,
	TermsandConditions,
	ShareExperienceSignIn,
	ShareExperienceSignUp,
} from 'pages';
import { Route, Routes } from 'react-router-dom';
import {
	HOME,
	ABOUT_US,
	BECOME_A_MEMBER,
	ANNOUNCEMENT,
	PROGRAM,
	PROJECT,
	BLOG,
	UPLOAD_A_VIDEO,
	WRITE_AN_ARTICLE,
	WATCH_VIDEOS,
	READ_ARTICLES,
	DONATE,
	PARTNER_WITH_US_COMPANY,
	PARTNER_WITH_US_INDIVIDUAL,
	GUEST_SPEAKER,
	SHOW_HOST,
	VOLUNTEER,
	SIGN_IN,
	SIGN_UP,
	PROGRAM_ARTICLE,
	TEAM,
	PARTNERS,
	BLOG_ARTICLE,
	TERMS_AND_CONDITIONS,
	SHARE_EXPERIENCE_SIGN_IN,
	SHARE_EXPERIENCE_SIGN_UP,
} from 'navigation/constants';

function RouterConfig() {
	return (
		<Routes>
			{/*************************PUBLIC ROUTES************************************** */}

			{/* List all public routes here */}
			<Route exact path={HOME} element={<Home />} />
			<Route path={ABOUT_US} element={<AboutUs />} />
			<Route path={BECOME_A_MEMBER} element={<BecomeAMember />} />
			<Route path={ANNOUNCEMENT} element={<Announcement />} />
			<Route path={PROGRAM} element={<Program />} />
			<Route path={PROJECT} element={<Project />} />
			<Route path={BLOG} element={<Blog />} />
			<Route path={WATCH_VIDEOS} element={<WatchVideos />} />
			<Route path={READ_ARTICLES} element={<ReadArticles />} />
			<Route path={DONATE} element={<Donate />} />
			<Route
				path={PARTNER_WITH_US_COMPANY}
				element={<PartnerWithUsCompany />}
			/>
			<Route
				path={PARTNER_WITH_US_INDIVIDUAL}
				element={<PartnerWithUsIndividual />}
			/>
			<Route path={GUEST_SPEAKER} element={<GuestSpeaker />} />
			<Route path={VOLUNTEER} element={<Volunteer />} />
			<Route path={SHOW_HOST} element={<ShowHost />} />
			<Route path={SIGN_IN} element={<SignIn />} />
			<Route path={SIGN_UP} element={<SignUp />} />
			<Route path={PROGRAM_ARTICLE} element={<ProgramArticle />} />
			<Route path={TEAM} element={<Team />} />
			<Route path={PARTNERS} element={<Partners />} />
			<Route path={BLOG_ARTICLE} element={<BlogArticle />} />
			<Route path={TERMS_AND_CONDITIONS} element={<TermsandConditions />} />
			<Route
				path={SHARE_EXPERIENCE_SIGN_IN}
				element={<ShareExperienceSignIn />}
			/>
			<Route
				path={SHARE_EXPERIENCE_SIGN_UP}
				element={<ShareExperienceSignUp />}
			/>

			<Route path={UPLOAD_A_VIDEO} element={<UploadAVideo />} />
			<Route path={WRITE_AN_ARTICLE} element={<WriteAnArticle />} />

			{/*************************PROTECTED ROUTES************************************** */}
			<Route
				element={<ProtectedRoutes redirectPath={SHARE_EXPERIENCE_SIGN_IN} />}
			>
				{/* LIST ALL PROTECTED ROUTES HERE */}
			</Route>

			{/*************************404************************************** */}

			{/* List All 404 routes here */}
			<Route path='*' element={<div>404 PAGE NOT FOUND</div>} />
		</Routes>
	);
}

export default RouterConfig;
