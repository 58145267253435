import React from 'react';
import AboutUsView from './AboutUsView';

export function AboutUsContainer() {
	return (
		<div>
			<AboutUsView />
		</div>
	);
}
