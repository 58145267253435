import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import {
	UpcomingEvent,
	OngoingEvent,
} from 'components/layout/AnnouncementSection';

function AnnouncementView() {
	return (
		<PageWrapper2>
			<div className='flex'>
				<div className='lg:w-[80%] xl:w-[80%] 2xl:w-[80%]'>
					<div className='px-20 py-4'>
						<div>
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
								Ongoing Event
							</h1>
							<OngoingEvent />
						</div>
					</div>
					<div className='px-20 py-4'>
						<div>
							<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
								Upcoming Event
							</h1>
							<UpcomingEvent />
						</div>
					</div>
				</div>
				<div className='lg:w-[20%] xl:w-[20%] 2xl:w-[20%]'></div>
			</div>

			<div className='px-20 py-4'>
				<div className='lg:w-[80%] xl:w-[80%] 2xl:w-[80%]'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
						Upcoming Event
					</h1>
					<UpcomingEvent />
				</div>
				<div className='lg:w-[80%] xl:w-[80%] 2xl:w-[80%]'>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-t from-purple to-gold w-80 text-2xl'>
						Upcoming Event
					</h1>
					<UpcomingEvent />
				</div>
				<div className='lg:w-[20%] xl:w-[20%] 2xl:w-[20%]'></div>
			</div>
		</PageWrapper2>
	);
}

export default AnnouncementView;
