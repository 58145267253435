import React from 'react';
import BlogArticleView from './BlogArticleView';

export function BlogArticleContainer() {
	return (
		<div>
			<BlogArticleView />
		</div>
	);
}
