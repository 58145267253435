import React from 'react';
import ContactUsForm from 'components/layout/forms/ContactUsForm';

function GetInTouchSection() {
	return (
		<div className='mb-10'>
			<ContactUsForm />
		</div>
	);
}

export default GetInTouchSection;
