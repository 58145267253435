import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { TermsandConditions } from 'components/modules/TermsandConditions';

function TermsandConditionsView() {
	return (
		<PageWrapper2>
			<TermsandConditions />
		</PageWrapper2>
	);
}

export default TermsandConditionsView;
