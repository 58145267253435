import { React } from 'react';
import { CommentData } from 'Data';
import { circle } from 'assets/images';

//This is the comment section under the program page
const Comment = () => {
	const currentPage = 1;
	const postsPerPage = 3;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = CommentData.slice(indexOfFirstPost, indexOfLastPost);
	return (
		<>
			{currentPosts.map((word, id) => (
				<div key={id} className='flex flex-row gap-x-6 pt-8 '>
					<div>
						<img src={circle} alt={word.title} className='w-full' />
					</div>
					<div>
						<h2 className='font-bold text-[#000000] text-base'>{word.title}</h2>
						<h6 className='font-semibold text-[#7A7B74] text-sm'>
							{word.date}
						</h6>
						<p className='text-[#000000] text-sm'>{word.comment}</p>
					</div>
				</div>
			))}
		</>
	);
};

export default Comment;
