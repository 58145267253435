import React from 'react';
import PartnerWithUsCompanyView from './PartnerWithUsCompanyView';

export function PartnerWithUsCompanyContainer() {
	return (
		<div>
			<PartnerWithUsCompanyView />
		</div>
	);
}
