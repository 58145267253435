import { daniel, adamu, maureen } from 'assets/images';

const Members = [
	{
		id: 1,
		image: daniel,
		name: 'Daniel Mwambonu',
		title: 'Founder BWC',
	},
	{
		id: 2,
		image: maureen,
		name: 'Maureen Chukwurah',
		title: 'Project Manager BWC',
	},
	{
		id: 3,
		image: adamu,
		name: 'Ewemade Adamu',
		title: 'HR Manager BWC',
	},
	{
		id: 4,
		image: daniel,
		name: 'Alice Makawa',
		title: 'Web Developer BWC',
	},
	{
		id: 5,
		image: daniel,
		name: 'Pauline Ondiek',
		title: 'Web Developer BWC',
	},
	{
		id: 6,
		image: daniel,
		name: 'Goodness Ubochi',
		title: 'Show Host BWC',
	},
	{
		id: 7,
		image: daniel,
		name: 'Janet Zagah',
		title: 'Ambassador BWC',
	},
	{
		id: 8,
		image: daniel,
		name: 'Bilikis Oriniowo',
		title: 'Scrum Master BWC',
	},
	{
		id: 9,
		image: daniel,
		name: 'Ntombizanele Moni',
		title: 'Web Developer BWC',
	},
	{
		id: 10,
		image: daniel,
		name: 'Ihejirika Chimgozirim',
		title: 'Member BWC',
	},
	{
		id: 11,
		image: daniel,
		name: 'Stella Alamezie',
		title: 'Product owner BWC',
	},
	{
		id: 12,
		image: daniel,
		name: 'Chioma Ugo-Nwachukwu',
		title: 'Show Host BWC',
	},
	{
		id: 13,
		image: daniel,
		name: 'Olamide Olaniyi',
		title: 'Web Developer BWC',
	},
	{
		id: 14,
		image: daniel,
		name: 'Mildred Makori',
		title: 'Web Developer BWC',
	},
	{
		id: 15,
		image: daniel,
		name: 'Eyerusalem Kebert',
		title: 'Member BWC',
	},
];

export default Members;
