import { React } from 'react';
import { AnnouncementData } from 'Data';

//This is the ongoing event section under the announcement page
const OngoingEvent = () => {
	const currentPage = 1;
	const postsPerPage = 1;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = AnnouncementData.slice(
		indexOfFirstPost,
		indexOfLastPost
	);

	return (
		<>
			{currentPosts.map((event, id) => (
				<div
					className='flex flex-col gap-y-4 md:flex-row md:gap-x-10 lg:flex-row lg:gap-x-10 xl:flex-row xl:gap-x-10 2xl:flex-row 2xl:gap-x-10 justify-center my-6'
					key={id}
				>
					<div className='flex flex-col justify-center'>
						<img src={event.image} alt={event.title} />
					</div>
					<div className='flex flex-col justify-center gap-y-2'>
						<h1 className='font-semibold text-2xl'>We are Live</h1>
						<h1 className='font-semibold text-2xl'>{event.title}</h1>
						<p className='text-sm'>{event.body}</p>
						<button className='w-32 h-10 text-base bg-purple rounded text-white'>
							<a rel='noreferrer' target='_blank' href={event.link}>
								Join Here
							</a>
						</button>
					</div>
				</div>
			))}
		</>
	);
};

export default OngoingEvent;
