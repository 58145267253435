import { useState, React } from 'react';
import { Link } from 'react-router-dom';
import { ProgramData } from 'Data';

//This is the program section under the program page
const ProgramSection = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const postsPerPage = 4;

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = ProgramData.slice(indexOfFirstPost, indexOfLastPost);
	const npage = Math.ceil(ProgramData.length / postsPerPage);
	const numbers = [...Array(npage + 1).keys()].slice(1);

	return (
		<>
			<div>
				{currentPosts.map((program) => (
					<div
						className='flex flex-col gap-y-4 md:flex-row md:gap-x-10 lg:flex-row lg:gap-x-10 xl:flex-row xl:gap-x-10 2xl:flex-row 2xl:gap-x-10 mt-12 mb-4 justify-center'
						key={program.id}
					>
						<div className='flex flex-col justify-center'>
							<img src={program.image} alt={program.title} />
						</div>
						<div className='flex flex-col justify-center gap-y-2'>
							<h1 className='font-semibold text-2xl'>{program.title}</h1>
							<p className='text-sm'>{program.body}</p>
							<div className='flex flex-row gap-x-2 text-sm'>
								<p>{program.date}</p>
								<p>|</p>
								<p>{program.time}</p>
							</div>
							<button className='w-40 h-10 text-base bg-purple rounded text-white mt-4'>
								<Link to={`/program-article/${program.title}`}>
									Watch Recording
								</Link>
							</button>
						</div>
					</div>
				))}
			</div>
			{/* pagination section */}
			<div className='grid grid-cols-3 text-sm mt-6'>
				<div></div>
				<div className='flex items-center font-medium gap-x-2'>
					{numbers.map((n, i) => (
						<a
							key={i}
							onClick={() => changeCPage(n)}
							href='#'
							className={`page-item ${
								currentPage === n ? 'active' : ''
							}border-solid border-[#000] border-2 px-4 py-2`}
						>
							{n}
						</a>
					))}
				</div>
				<div></div>
			</div>
		</>
	);
	function changeCPage(id) {
		setCurrentPage(id);
	}
};

export default ProgramSection;
