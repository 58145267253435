import React from 'react';
import ShareExperienceSignInView from './ShareExperienceSignInView';

export function ShareExperienceSignInContainer() {
	return (
		<div>
			<ShareExperienceSignInView />
		</div>
	);
}
