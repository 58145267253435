import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { success } from 'assets/images';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function BecomeAMember() {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues: {
			fullName: '',
			email: '',
			linkedInUrl: '',
			phoneNumber: '',
			attachCv: '',
			headShot: '',
			checkbox: false,
		},
		validationSchema: Yup.object({
			fullName: Yup.string()
				.min(3, 'Must be 3 characters or less')
				.required('Required'),
			linkedInUrl: Yup.string(),
			email: Yup.string().email().required('Required'),
			phoneNumber: Yup.string().min(10).matches(phoneRegExp),
			attachCv: Yup.mixed().required('Required'),
			headShot: Yup.mixed().required('Required'),
			checkbox: Yup.bool()
				.required('Required')
				.oneOf([true], 'Accept the terms and conditions'),
		}),
		onSubmit: () => {
			console.log(formik.values);
			axios({
				method: 'POST',
				url: 'http://localhost:1337/form/becomeAMember',
				data: formik.values,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					console.log(response);
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submitted Successfully',
						text: 'You have successfully filled the Become A Member Form.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
					navigate('/become-a-member');
				})
				.catch(function () {
					Swal.fire({
						imageUrl: success,
						imageWidth: 100,
						imageHeight: 100,
						imageAlt: 'Success',
						title: 'Submission was Unsuccessful',
						text: 'Kindly try again.',
						width: 600,
						color: '#fff',
						padding: '3em',
						background: '#000',
						confirmButtonColor: '#800080',
						backdrop: 'rgba(255,255,255,0.3)',
					});
					navigate('/become-a-member');
				});
		},
	});
	return (
		<form
			onSubmit={formik.handleSubmit}
			className='bg-[#0E1022] py-8 md:py-8 lg:px-8 lg:py-8 xl:px-8 xl:py-8 2xl:px-8 2xl:py-8 mb-0'
		>
			<h1 className='text-3xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl text-center text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold pb-6 pt-4'>
				Become a Member
			</h1>
			<p className='text-base my-2 text-white text-center px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 pb-4 2xl:text-center'>
				Thanks for your interest in becoming our member for Black Woman Can.
				Kindly fill the form below and we will reach out to you!
			</p>
			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='fullName' className='text-white text-base'>
					Full Name <b className='text-red text-base'>*</b>
				</label>
				<input
					name='fullName'
					type='text'
					className='p-1 rounded text-sm'
					placeholder='Enter your Full Name'
					onChange={formik.handleChange}
				/>
				{formik.touched.fullName && formik.errors.fullName ? (
					<div className='text-red text-base'>{formik.errors.fullName}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='email' className='text-white text-base'>
					Email Address <b className='text-red text-base'>*</b>
				</label>
				<input
					name='email'
					type='email'
					className='p-1 rounded text-sm'
					placeholder='Enter your Email Address'
					onChange={formik.handleChange}
				/>
				{formik.touched.email && formik.errors.email ? (
					<div className='text-red text-base'>{formik.errors.email}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='linkedInUrl' className='text-white text-base'>
						LinkedIn URL
					</label>
					<input
						name='linkedInUrl'
						type='url'
						className='w-full p-1 rounded text-sm'
						placeholder='Enter your LikedIn URL'
						onChange={formik.handleChange}
					/>
					{formik.touched.linkedInUrl && formik.errors.linkedInUrl ? (
						<div className='text-red text-base'>
							{formik.errors.linkediInUrl}
						</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='phoneNumber' className='text-white text-base'>
						Phone Number
					</label>
					<input
						name='phoneNumber'
						type='tel'
						className='w-full p-1 rounded bg-white text-sm'
						placeholder='Enter your Phone Number'
						onChange={formik.handleChange}
					/>
					{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
						<div className='text-red text-base'>
							{formik.errors.phoneNumber}
						</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='attachCv' className='text-white text-base'>
						Attach your CV <b className='text-red text-base'>*</b>
					</label>
					<input
						type='file'
						name='attachCv'
						className='block
							w-full
							px-3
							py-1
							text-sm
							text-black
							bg-white bg-clip-padding
							border-2 border-solid border-white
							rounded'
						placeholder='Upload Your Cv'
						accept='.pdf'
						onChange={(e) =>
							formik.setFieldValue('attachCv', e.currentTarget.files[0])
						}
						// onChange={(e) => {
						// 	const fileReader = new FileReader();
						// 	fileReader.onload = () => {
						// 		if (fileReader.readyState === 2) {
						// 			formik.setFieldValue('attachCv', fileReader.result);
						// 		}
						// 	};
						// 	fileReader.readAsDataURL(e.target.files[0]);
						// }}
					/>
					{formik.touched.attachCv && formik.errors.attachCv ? (
						<div className='text-red text-base'>{formik.errors.attachCv}</div>
					) : null}
				</div>
				<div className='px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
					<label htmlFor='headShot' className='text-white text-base'>
						Headshot <b className='text-red text-base'>*</b>
					</label>
					<input
						type='file'
						name='headShot'
						className='block
							w-full
							px-3
							py-1
							text-sm
							text-black
							bg-white bg-clip-padding
							border border-solid border-white
							rounded'
						placeholder='Upload Your Image'
						accept='image/*'
						onChange={(e) =>
							formik.setFieldValue('headShot', e.currentTarget.files[0])
						}
						// onChange={(e) => {
						// 	const fileReader = new FileReader();
						// 	fileReader.onload = () => {
						// 		if (fileReader.readyState === 2) {
						// 			formik.setFieldValue('headShot', fileReader.result);
						// 		}
						// 	};
						// 	fileReader.readAsDataURL(e.target.files[0]);
						// }}
					/>
					{formik.touched.headShot && formik.errors.headShot ? (
						<div className='text-red text-base'>{formik.errors.headShot}</div>
					) : null}
				</div>
			</div>

			<div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 px-10 md:px-20 lg:px-20 xl:px-20 2xl:px-20 mb-4'>
				<label htmlFor='checkbox' className='text-white text-base'>
					<input
						type='checkbox'
						name='checkbox'
						className='bg-white rounded mr-2 text-sm'
						onChange={formik.handleChange}
					/>
					By ticking this box, you agree to the{' '}
					<Link
						to='/terms-and-conditions'
						target='_blank'
						rel='noopener noreferrer'
						className='text-purple mr-2'
					>
						terms and conditions
					</Link>
					of our organization <b className='text-red text-base'>*</b>
				</label>
				{formik.touched.checkbox && formik.errors.checkbox ? (
					<div className='text-red text-base'>{formik.errors.checkbox}</div>
				) : null}
			</div>

			<div className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 pb-4'>
				<div></div>
				<div className='flex justify-center'>
					<button
						type='submit'
						className='w-32 h-12 text-base bg-purple rounded text-white'
					>
						Register
					</button>
				</div>
				<div></div>
			</div>
		</form>
	);
}

export default BecomeAMember;
