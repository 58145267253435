import { stella } from 'assets/videos';

const video = [
	{
		id: 1,
		title: 'Empowering The Girl Child',
		video: stella,
		date: '2 days ago',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		video: stella,
		date: '2 days ago',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		video: stella,
		date: '2 days ago',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		video: stella,
		date: '2 days ago',
	},
	{
		id: 1,
		title: 'Empowering The Girl Child',
		video: stella,
		date: '2 days ago',
	},
];

export default video;
