import React from 'react';
import PropTypes from 'prop-types';

function SubmitButton({
	isSubmitting,
	className,
	classNameOnSubmit,
	handleClick,
	buttonText,
	buttonTextOnSubmit,
	buttonTextOnValidate,
	isValidating,
}) {
	const props = {
		type: 'submit',
		className: `${className} ${
			(isSubmitting || isValidating) && classNameOnSubmit
		}`,
		onClick: handleClick,
	};
	return (
		<>
			<button {...props}>
				{isSubmitting && !isValidating && buttonTextOnSubmit}
				{!isSubmitting && isValidating && buttonTextOnValidate}
				{!isSubmitting && !isValidating && buttonText}
			</button>
		</>
	);
}

SubmitButton.propTypes = {
	isSubmitting: PropTypes.bool,
	isValidating: PropTypes.bool,
	className: PropTypes.string,
	buttonText: PropTypes.string,
	buttonTextOnSubmit: PropTypes.string,
	buttonTextOnValidate: PropTypes.string,
	classNameOnSubmit: PropTypes.string,
	handleClick: PropTypes.func,
};

SubmitButton.defaultProps = {
	isSubmitting: false,
	isValidating: false,
	className: 'btn bg-primary-100 btn-block hover:text-primary-content',
	classNameOnSubmit: 'btn-disabled loading hover:cursor-wait',
	buttonText: 'SUBMIT',
	buttonTextOnSubmit: 'Submitting, please wait...',
	buttonTextOnValidate: 'Validating, please wait...',
};

export default SubmitButton;
