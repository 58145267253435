import { programs } from 'assets/images';
import { stella } from 'assets/videos';

const Programs = [
	{
		id: 1,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay1',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 2,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay2',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 3,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay3',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 4,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay4',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 5,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay5',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 6,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay6',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 7,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay7',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
	{
		id: 8,
		image: programs,
		video: stella,
		title: 'Its okay not to be okay8',
		body: 'Join us for another episode of Black Mental Health Matters show as we work to destigmatize Mental Health conversation. The show is free but you have to register to be ale to attend',
		date: 'Sat, 15 Oct 2022',
		time: '10:00AM, WAT',
	},
];

export default Programs;
