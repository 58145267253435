import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { ProjectSection } from 'components/layout/ProjectSection';
import { project } from 'assets/images';

function ProjectView() {
	return (
		<PageWrapper2>
			<div style={{ backgroundImage: `url(${project})` }} className='bg-cover'>
				<div className='text-white text-center py-[100px]'>
					<h2 className='font-[700] text-3xl'>BWC PROJECT</h2>
				</div>
			</div>
			<div className='py-4 px-8 md:px-20 lg:px-80 xl:px-80 2xl:px-80'>
				<div>
					<ProjectSection />
				</div>
			</div>
		</PageWrapper2>
	);
}

export default ProjectView;
