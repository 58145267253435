import React from 'react';
import { PageWrapper2 } from 'components/modules/Wrapper2';
import { TeamMembers } from 'components/layout/TeamMembers';

function TeamView() {
	return (
		<PageWrapper2>
			<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'>
				<div></div>
				<div>
					<h1 className='text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple to-gold w-100 text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl text-center'>
						Meet Our Team
					</h1>
				</div>
				<div></div>
			</div>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 mt-10'>
				<TeamMembers />
			</div>
		</PageWrapper2>
	);
}

export default TeamView;
