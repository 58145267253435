import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useTailwindSquareSizing } from 'hooks';

const { sizes, getSize } = useTailwindSquareSizing();

const Arrow = ({ direction, size }) => {
	let rotate = '';

	switch (direction) {
		case 'left':
			rotate = 'rotate-90 -translate-x-1';
			break;

		case 'right':
			rotate = '-rotate-90 translate-x-1';
			break;

		case 'top':
			rotate = 'rotate-180';
			break;

		case 'bottom':
		default:
			rotate = 'rotate-0';
			break;
	}

	return (
		<svg
			className={`${rotate} ${getSize(size)}`}
			viewBox='0 0 13 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1 1L6.5 8L12 1'
				stroke='currentColor'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

Arrow.propTypes = {
	direction: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
	size: PropTypes.oneOf(sizes),
};

export default memo(Arrow);
