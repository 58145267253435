import React from 'react';
import WatchVideosView from './WatchVideosView';

export function WatchVideosContainer() {
	return (
		<div>
			<WatchVideosView />
		</div>
	);
}
