import React from 'react';
import { home } from 'assets/images';

function WelcomeSection() {
	return (
		<>
			<div
				style={{ backgroundImage: `url(${home})` }}
				className='bg-cover pt-[20px] md:pt-[40px] lg:pt-[40px] xl:pt-[40px] 2xl:pt-[40px] pb-[100px] md:pb-[300px] lg:pb-[300px] xl:pb-[300px] 2xl:pb-[300px]'
			>
				<div className='text-white text-xl text-center mt-20 md:mt-40 lg:mt-40 xl:mt-40 2xl:mt-40'>
					<h2 className='font-bold text-2xl md:text-4xl lg:text-4xl xl:text-4xl 2xl:text-4xl'>
						Black Woman Can
					</h2>
					<br />
					<p className='text-center font-semibold text-gold text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl'>
						Black Woman has done it! Black Woman Making it!
					</p>
				</div>
			</div>
		</>
	);
}

export default WelcomeSection;
