import React from 'react';
import ShowHostView from './ShowHostView';

export function ShowHostContainer() {
	return (
		<div>
			<ShowHostView />
		</div>
	);
}
